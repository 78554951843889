
import axios from 'axios'
import Swal from 'sweetalert2';
import { apiKey, server } from '../../Config/server';

export function login(input, navigate) {
    return (dispatch, getState) => {
        // Swal.showLoading()
        // axios({
        //     method: 'post',
        //     url: `${server}login`,
        //     data: { username: input.username, password: input.password },
        //     headers: {
        //         'Accept': 'application/json'
        //     }
        // })
        //     .then((data) => {
        //         localStorage.setItem('token', data.data.token)
        //         localStorage.setItem('namauser', data.data.data.nama)
        //         localStorage.setItem('role', data.data.data.role)
        //         dispatch({
        //             type: "GETPROFIL",
        //             payload: data.data.data
        //         })
        //         Swal.close()
                navigate(`/loged/dashboard`)
        //     })
        //     .catch(function (error) {
        //         Swal.close()
        //         Swal.fire({
        //             icon: 'error',
        //             title: 'Username atau Password Salah',
        //             text: 'Silahkan masukkan username dan password anda dengan benar!',
        //         })
        //     });
    }
}


export function setProfil() {
    return (dispatch, getState) => {
        dispatch({
            type: "GETPROFIL",
            payload: {nama: localStorage.namauser, role: localStorage.role}
        })
    }
}


export function dLogout(navigate) {
    return (dispatch, getState) => {
        Swal.showLoading()
        localStorage.clear()
        dispatch({
            type: "GETPROFIL",
            payload: null
        })
        Swal.close()
        navigate(`/`)
    }
}

export function getGuru() {
    return (dispatch, getState) => {
        Swal.showLoading()
        axios({
            url: `${server}guru/view`,
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.token}`,
                'x-api-key': apiKey
            }
        })
            .then(({ data }) => {
                dispatch({
                    type: "GETGURU",
                    payload: data.data
                })
                Swal.close()
            })
            .catch(err => {
                console.log(err);
            })
    }
}


export function getPesertaDidik() {
    return (dispatch, getState) => {
        Swal.showLoading()
        axios({
            url: `${server}pesertadidik/view`,
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.token}`,
                'x-api-key': apiKey
            }
        })
            .then(({ data }) => {
                dispatch({
                    type: "GETPESERTADIDIK",
                    payload: data.data
                })
                Swal.close()
            })
            .catch(err => {
                console.log(err);
            })
    }
}

export function getTenagapendidik() {
    return (dispatch, getState) => {
        Swal.showLoading()
        axios({
            url: `${server}tenagapendidik/view`,
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.token}`,
                'x-api-key': apiKey
            }
        })
            .then(({ data }) => {
                dispatch({
                    type: "GETTENAGAPENDIDIK",
                    payload: data.data
                })
                Swal.close()
            })
            .catch(err => {
                console.log(err);
            })
    }
}

export function getSarpras() {
    return (dispatch, getState) => {
        Swal.showLoading()
        axios({
            url: `${server}sarpras/view`,
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.token}`,
                'x-api-key': apiKey
            }
        })
            .then(({ data }) => {
                dispatch({
                    type: "GETSARPRAS",
                    payload: data.data
                })
                Swal.close()
            })
            .catch(err => {
                console.log(err);
            })
    }
}

export function getSekolah() {
    return (dispatch, getState) => {
        Swal.showLoading()
        axios({
            url: `${server}sekolah/view`,
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.token}`,
                'x-api-key': apiKey
            }
        })
            .then(({ data }) => {
                dispatch({
                    type: "GETSEKOLAH",
                    payload: data.data
                })
                Swal.close()
            })
            .catch(err => {
                console.log(err);
            })
    }
}

export function getPengguna() {
    return (dispatch, getState) => {
        Swal.showLoading()
        axios({
            url: `${server}user/view`,
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.token}`,
                'x-api-key': apiKey
            }
        })
            .then(({ data }) => {
                let tempPeng = data.data.filter(e => e.data_sekolah !== null)
                dispatch({
                    type: "GETPENGGUNA",
                    payload: tempPeng
                })
                Swal.close()
            })
            .catch(err => {
                console.log(err);
            })
    }
}

export function addSekolah(input, navigate) {
    return (dispatch, getState) => {
        Swal.showLoading()
        axios({
            url: `${server}sekolah/create`,
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.token}`,
                'x-api-key': apiKey
            },
            data: input
        })
            .then(({ data }) => {
                dispatch({
                    type: "ADDSEKOLAH",
                    payload: data.data
                })
                Swal.close()
                navigate(-1)
            })
            .catch(err => {
                console.log(err);
            })
    }
}

export function addPengguna(input, navigate) {
    return (dispatch, getState) => {
        Swal.showLoading()
        axios({
            url: `${server}create/user`,
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.token}`,
                'x-api-key': apiKey
            },
            data: input
        })
            .then(({ data }) => {
                dispatch({
                    type: "ADDPENGGUNA",
                    payload: data.data
                })
                Swal.close()
                navigate(-1)
            })
            .catch(err => {
                console.log(err);
            })
    }
}

export function deleteSekolah(id, navigate) {
    return (dispatch, getState) => {
        Swal.showLoading()
        axios({
            url: `${server}sekolah/delete/${id}`,
            method: 'delete',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.token}`,
                'x-api-key': apiKey
            }
        })
            .then(({ data }) => {
                dispatch({
                    type: "DELETESEKOLAH",
                    payload: id
                })
                Swal.close()
                Swal.fire(
                    'Sukses!',
                    'Data Berhasil Dihapus',
                    'success'
                )
                navigate(-1)
            })
            .catch(err => {
                console.log(err);
            })
    }
}

export function deletePengguna(id, navigate) {
    return (dispatch, getState) => {
        Swal.showLoading()
        axios({
            url: `${server}delete/user/${id}`,
            method: 'delete',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.token}`,
                'x-api-key': apiKey
            }
        })
            .then(({ data }) => {
                dispatch({
                    type: "DELETEPENGGUNA",
                    payload: id
                })
                Swal.close()
                Swal.fire(
                    'Sukses!',
                    'Data Berhasil Dihapus',
                    'success'
                )
                navigate(-1)
            })
            .catch(err => {
                console.log(err);
            })
    }
}

export function updateGuru(input, navigate) {
    return (dispatch, getState) => {
        Swal.showLoading()
        let temp = new FormData()
        temp.append('file', input.file)
        temp.set('sekolah_id', input.sekolah_id)
        axios({
            url: `${server}import/guru`,
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.token}`,
                'x-api-key': apiKey
            },
            data: temp
        })
            .then(({ data }) => {
                dispatch(getGuru())
                Swal.close()
                Swal.fire(
                    'Sukses!',
                    'Data Berhasil Diperbaharui',
                    'success'
                )
                navigate(-1)
            })
            .catch(err => {
                console.log(err);
            })
    }
}


export function deleteGuru(id, navigate) {
    return (dispatch, getState) => {
        Swal.showLoading()
        axios({
            url: `${server}guru/delete/${id}`,
            method: 'delete',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.token}`,
                'x-api-key': apiKey
            }
        })
            .then(({ data }) => {
                dispatch({
                    type: "DELETEGURU",
                    payload: id
                })
                Swal.close()
                Swal.fire(
                    'Sukses!',
                    'Data Berhasil Dihapus',
                    'success'
                )
                navigate(-1)
            })
            .catch(err => {
                console.log(err);
            })
    }
}



export function updatePesertaDidik(input, navigate) {
    return (dispatch, getState) => {
        Swal.showLoading()
        let temp = new FormData()
        temp.append('file', input.file)
        temp.set('sekolah_id', input.sekolah_id)
        axios({
            url: `${server}import/pesertadidik`,
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.token}`,
                'x-api-key': apiKey
            },
            data: temp
        })
            .then(({ data }) => {
                dispatch(getPesertaDidik())
                Swal.close()
                Swal.fire(
                    'Sukses!',
                    'Data Berhasil Diperbaharui',
                    'success'
                )
                navigate(-1)
            })
            .catch(err => {
                console.log(err);
            })
    }
}

export function deletePesertaDidik(id, navigate) {
    return (dispatch, getState) => {
        Swal.showLoading()
        axios({
            url: `${server}pesertadidik/delete/${id}`,
            method: 'delete',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.token}`,
                'x-api-key': apiKey
            }
        })
            .then(({ data }) => {
                dispatch({
                    type: "DELETEPESERTADIDIK",
                    payload: id
                })
                Swal.close()
                Swal.fire(
                    'Sukses!',
                    'Data Berhasil Dihapus',
                    'success'
                )
                navigate(-1)
            })
            .catch(err => {
                console.log(err);
            })
    }
}


export function updateTenagapendidik(input, navigate) {
    return (dispatch, getState) => {
        Swal.showLoading()
        let temp = new FormData()
        temp.append('file', input.file)
        temp.set('sekolah_id', input.sekolah_id)
        axios({
            url: `${server}import/tenagapendidik`,
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.token}`,
                'x-api-key': apiKey
            },
            data: temp
        })
            .then(({ data }) => {
                dispatch(getTenagapendidik())
                Swal.close()
                Swal.fire(
                    'Sukses!',
                    'Data Berhasil Diperbaharui',
                    'success'
                )
                navigate(-1)
            })
            .catch(err => {
                console.log(err);
            })
    }
}

export function deleteTenagapendidik(id, navigate) {
    return (dispatch, getState) => {
        Swal.showLoading()
        axios({
            url: `${server}tenagapendidik/delete/${id}`,
            method: 'delete',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.token}`,
                'x-api-key': apiKey
            }
        })
            .then(({ data }) => {
                dispatch({
                    type: "DELETETENAGAPENDIDIK",
                    payload: id
                })
                Swal.close()
                Swal.fire(
                    'Sukses!',
                    'Data Berhasil Dihapus',
                    'success'
                )
                navigate(-1)
            })
            .catch(err => {
                console.log(err);
            })
    }
}

export function updateSarpras(input, navigate) {
    return (dispatch, getState) => {
        Swal.showLoading()
        let temp = new FormData()
        temp.append('file', input.file)
        temp.set('sekolah_id', input.sekolah_id)
        axios({
            url: `${server}import/sarpras`,
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.token}`,
                'x-api-key': apiKey
            },
            data: temp
        })
            .then(({ data }) => {
                dispatch(getSarpras())
                Swal.close()
                Swal.fire(
                    'Sukses!',
                    'Data Berhasil Diperbaharui',
                    'success'
                )
                navigate(-1)
            })
            .catch(err => {
                console.log(err);
            })
    }
}

export function deleteSarpras(id, navigate) {
    return (dispatch, getState) => {
        Swal.showLoading()
        axios({
            url: `${server}sarpras/delete/${id}`,
            method: 'delete',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.token}`,
                'x-api-key': apiKey
            }
        })
            .then(({ data }) => {
                dispatch({
                    type: "DELETESARPRAS",
                    payload: id
                })
                Swal.close()
                Swal.fire(
                    'Sukses!',
                    'Data Berhasil Dihapus',
                    'success'
                )
                navigate(-1)
            })
            .catch(err => {
                console.log(err);
            })
    }
}
