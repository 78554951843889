import { apiClient } from "../../../../../service";

export const viewLabApi = (filter) => {
  const queryString = Object.keys(filter)
    .map((key) => {
      if (filter[key]) {
        return `${key}=${filter[key]}`;
      }
    })
    .join("&");
  return apiClient.get(`/lab/view?${queryString}`);
};
export const addLabApi = (form) => apiClient.post(`/lab/create`, form);
export const deleteLabApi = (id) => apiClient.post(`/lab/delete/${id}`);
export const detailLabApi = (id) => apiClient.get(`/lab/view/detail/${id}`);
export const editLabApi = (id, form) => apiClient.post(`/lab/update/${id}`, form);
export const viewOptLabApi = () => apiClient.get(`/lab/view/options`);