import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Swal from "sweetalert2";
import TextInput from "./Input/TextInput";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import InputSelect from "./Input/InputSelect";
import { useDokterStore } from "../Views/Pages/Dokter/store";
import SelectPasien from "./Input/SelectPasien";
import InputSelectLabel from "./Input/Select";

export default function ModalAddAppoinment(props) {
  const [pasien, setPasien] = useState(null);
  const [input, setInput] = useState({
    tanggal: "",
    poliklinik: "",
    idDokter: "",
    caraBayar: "",
  });
  const [dokter, actDokter] = useDokterStore();

  useEffect(() => {
    if (!dokter.optDokters) actDokter.loadOpt();
  }, []);

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setInput((inp) => ({ ...inp, [name]: value }));
  };

  async function add(e) {
    e.preventDefault();
    Swal.showLoading();
    const res = await props.action.addData({ ...input, idPasien: pasien.id });
    if (res.ok) {
      await props.action.loadData();
      props.close();
      setInput({
        tanggal: "",
        poliklinik: "",
        idDokter: "",
        caraBayar: "",
      });
      Swal.fire({
        title: "Sukses tambah data",
        timer: 2000,
        icon: "success",
      });
    } else {
      Swal.fire({
        title: res?.err ?? "Gagal Tambah Data",
        timer: 2000,
        icon: "error",
      });
    }
  }

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>
          Tambah Appoinment
          <Divider sx={{ mt: "12px" }} />
        </DialogTitle>
        <form onSubmit={add}>
          <DialogContent>
            <Stack spacing={2}>
              <TextInput
                label="Tanggal"
                onChange={onChangeInput}
                name="tanggal"
                value={input.tanggal}
                type="date"
                required={true}
              />
              <InputSelectLabel
                options={["Tunai", "Non Tunai", "Lainnya"]}
                label="Pembayaran"
                onChange={onChangeInput}
                name="caraBayar"
                value={input.caraBayar}
                required={true}
              />
              <SelectPasien
                setValue={setPasien}
                value={pasien}
                required={true}
              />
              <InputSelectLabel
                options={["Poli Umum", "Poli Gigi"]}
                label="Poliklinik"
                onChange={onChangeInput}
                name="poliklinik"
                value={input.poliklinik}
                required={true}
              />
              <InputSelect
                label="Pilih Dokter"
                onChange={onChangeInput}
                name="idDokter"
                value={input.idDokter}
                options={dokter?.optDokters ?? []}
                required={true}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button type="submit">Submit</Button>
            <Button type="button" onClick={props.close}>
              Batal
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
