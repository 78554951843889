import React from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import TextInput from "../../../Components/Input/TextInput";
import BtnCus from "../../../Components/Btn/BtnCus";
import InputSelect from "../../../Components/Input/Select";
import { useState } from "react";
import Swal from "sweetalert2";
import { usePasienStore } from "./store";

export default function TambahPasien() {
  const [, action] = usePasienStore()
  const navigate = useNavigate();
  const [input, setInput] = useState({
    nik:'',
    noKk:'',
    kedudukan:'',
    namaLengkap:'',
    jenisKelamin:'',
    golDarah:'',
    agama:'',
    tempatlahir:'',
    tanggalLahir:'',
    statusPernikahan:'',
    pendidikanTerakhir:'',
    alamat:'',
    noHp:'',
  })

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setInput((inp) => ({ ...inp, [name]: value }));
  };

  async function submit(e) {
    e.preventDefault();
    Swal.showLoading();
    const res = await action.addData(input);
    if (res.ok) {
      Swal.close();
      Swal.fire({
        title: "Sukses tambah data",
        timer: 2000,
        icon: "success",
      });
      navigate("/loged/data-pasien");
    } else {
      Swal.fire({
        title: res?.err ?? "Gagal Tambah Data",
        timer: 2000,
        icon: "error",
      });
    }
  }

  return (
    <Paper sx={{ borderRadius: "12px" }}>
      <div style={{ padding: "20px" }}>
        <Typography variant="h5">Tambah Pasien</Typography>
      </div>
      <Divider />
      <Container maxWidth="md">
        <div style={{ padding: "20px" }}>
          <form
            onSubmit={submit}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Stack spacing={2}>
            <TextInput
                label="Nama Lengkap"
                onChange={onChangeInput}
                name="namaLengkap"
                value={input.namaLengkap}
                required={true}
              />
             <TextInput
                label="NIK"
                onChange={onChangeInput}
                name="nik"
                value={input.nik}
              />
               <TextInput
                label="No KK"
                onChange={onChangeInput}
                name="noKk"
                value={input.noKk}
                required={true}
              />
              <InputSelect
                label="Jenis Kelamin"
                options={["Laki-laki", "Perempuan"]}
                onChange={onChangeInput}
                name="jenisKelamin"
                value={input.jenisKelamin}
                required={true}
              />
              <TextInput
                label="Tempat Lahir"
                onChange={onChangeInput}
                name="tempatlahir"
                value={input.tempatlahir}
                required={true}
              />
              <TextInput
                label="Tanggal Lahir"
                onChange={onChangeInput}
                name="tanggalLahir"
                value={input.tanggalLahir}
                required={true}
                type='date'
              />
             <TextInput
                label="Alamat"
                onChange={onChangeInput}
                name="alamat"
                value={input.alamat}
                required={true}
              />
              <TextInput
                label="No Hp"
                onChange={onChangeInput}
                name="noHp"
                value={input.noHp}
                required={true}
              />
                <InputSelect
                label="Agama"
                options={["Islam", "Kristen Katolik","Kristen Protestan", "Hindu", 'Budha']}
                onChange={onChangeInput}
                name="agama"
                value={input.agama}
                required={true}
              />
                <InputSelect
                label="Golongan Darah"
                options={["A", "B", "AB", "O"]}
                onChange={onChangeInput}
                name="golDarah"
                value={input.golDarah}
                required={true}
              />
                <InputSelect
                label="Pendidikan Terakhir"
                options={["SD", "SLTP", "SLTA", "PT"]}
                onChange={onChangeInput}
                name="pendidikanTerakhir"
                value={input.pendidikanTerakhir}
              />
                <InputSelect
                label="Status Pernikahan"
                options={["Kawin", "Belum Kawin", "Cerai Hidup", 'Cerai Mati']}
                onChange={onChangeInput}
                name="statusPernikahan"
                value={input.statusPernikahan}
              />
            </Stack>
            <div style={{ marginTop: "20px", marginLeft: "185px" }}>
              <BtnCus
                size="small"
                variant="contained"
                type="submit"
                color="primary"
                text="Submit"
                style={{ width: "100px" }}
              />
              <BtnCus
                size="small"
                variant="outlined"
                text="Batal"
                style={{ marginLeft: "12px", width: "100px", color: "#f37435" }}
                onClick={() => navigate(-1)}
              />
            </div>
          </form>
        </div>
      </Container>
    </Paper>
  );
}
