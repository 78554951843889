import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, Typography } from "@mui/material";
import { usePasienStore } from "../../Views/Pages/Pasien/store";


export default function SelectPasien({ value, setValue }) {
  const [, action] = usePasienStore();
  const [search, setSearch] = React.useState("");
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    loadOpt();
  }, [search]);

  const loadOpt = async () => {
    const res = await action.loadOpt({ search: search });
    if (res) {
      setOptions(res);
    }
  };

  return (
    <div className="div-input">
      <div className="label-input">Pilih Pasien</div>
      <Autocomplete
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        inputValue={search}
        onInputChange={(event, newInputValue) => {
          setSearch(newInputValue);
        }}
        value={value}
        fullWidth
        size="small"
        isOptionEqualToValue={(option, value) =>
          option.namaLengkap === value.namaLengkap
        }
        filterOptions={(x) => x}
        autoComplete
        includeInputInList
        filterSelectedOptions
        getOptionLabel={(option) => option.namaLengkap}
        options={options}
        // loading={loading}
        renderOption={(props, option) => (
          <Box {...props}>
            <Typography>{option.namaLengkap}</Typography>
            <Typography variant="caption">{option.nik}</Typography>
          </Box>
        )}
        renderInput={(params) => (
          <TextField {...params} placeholder="Pilih Pasien" />
        )}
      />
    </div>
  );
}
