

import React, { useState } from "react";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import bg from "../../Assets/images/bg-2.png";
import logo from "../../Assets/images/logo.png";
import { useNavigate, useLocation } from "react-router";
import Grid from "@mui/material/Grid";
import { useLoginStore } from "./store";
import Swal from "sweetalert2";
import { Navigate } from "react-router";

export default function Login() {
  const [state, action] = useLoginStore();
  const location = useLocation();
  const [input, setInput] = useState({ username: "", password: "" });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [err, setErr] = useState(false);
  let from = location.state?.from?.pathname || "/loged/dashboard";

  function onChangeInput(event) {
    let { name, value } = event.target;
    setInput({
      ...input,
      [name]: value,
    });
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  async function submit(e) {
    if (!input.username || !input.password) {
      setErr(true);
      window.scrollTo(0, 0);
    } else {
      e.preventDefault();
      Swal.showLoading();
      const res = await action.login(input);
      if (res) {
        Swal.fire({
          title: "Sukses login",
          timer: 2000,
          icon: "success",
        });
        navigate("/loged/dashboard");
        Swal.close();
      } else {
        Swal.close();
        Swal.fire({
          title: "Gagal Login",
          text: "Username atau password salah!",
          timer: 2000,
          icon: "error",
        });
      }
    }
  }

  if (state.isAuthed && state.profile) return <Navigate to={from} />;

  return (
    <Grid container spacing={0} className="root-login">
      <Grid item md={8} sm={7} xs={12} className="box-left-login">
        <img src={bg} className="bg-login" style={{ width: "85%" }} />
      </Grid>
      <Grid
        item
        md={4}
        sm={5}
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div className="box-form">
          <img src={logo} alt="logo" className="logo-login" />
          <div className="head-login">
          Sistem Manajemen Klinik
          </div>
          <div className="sub-head-login">
            Silahkan masuk dengan akun Anda
            <br />
            untuk melanjutkan
          </div>
          <form onSubmit={submit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              error={err && !input.username}
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={input.username}
              onChange={onChangeInput}
            />
            <TextField
              error={err && !input.password}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              value={input.password}
              onChange={onChangeInput}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <Visibility className="placeholder" />
                      ) : (
                        <VisibilityOff className="placeholder" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className="btn-login">
              <Button
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                sx={{ color: "white" }}
              >
                Login
              </Button>
            </div>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
