import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import TextInput from "../../../Components/Input/TextInput";
import BtnCus from "../../../Components/Btn/BtnCus";
import Swal from "sweetalert2";
import { useDokterStore } from "./store";
import { TextField } from "@mui/material";
import { useEffect } from "react";

const jadwal = ["senin", "selasa", "rabu", "kamis", "jumat", "sabtu", "minggu"];

export default function EditDokter() {
  const { id } = useParams();
  const [, action] = useDokterStore();
  const navigate = useNavigate();
  const [input, setInput] = useState({
    namaLengkap: "",
    poliklinik: "",
    noHp: "",
    senin1: "",
    senin2: "",
    selasa1: "",
    selasa2: "",
    rabu1: "",
    rabu2: "",
    kamis1: "",
    kamis2: "",
    jumat1: "",
    jumat2: "",
    sabtu1: "",
    sabtu2: "",
    minggu1: "",
    minggu2: "",
  });

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setInput((inp) => ({ ...inp, [name]: value }));
  };

  const loadData = async () => {
    Swal.showLoading();
    const res = await action.loadDetail(id);
    if (res) {
      setInput({
        namaLengkap: res.namaLengkap,
        poliklinik: res.poliklinik,
        noHp: res.noHp,
        senin1: res.senin ? res.senin?.split(" - ")[0] : "",
        senin2: res.senin ? res.senin?.split(" - ")[1] : "",
        selasa1: res.selasa ? res.selasa?.split(" - ")[0] : "",
        selasa2: res.selasa ? res.selasa?.split(" - ")[1] : "",
        rabu1: res.rabu ? res.rabu?.split(" - ")[0] : "",
        rabu2: res.rabu ? res.rabu?.split(" - ")[1] : "",
        kamis1: res.kamis ? res.kamis?.split(" - ")[0] : "",
        kamis2: res.kamis ? res.kamis?.split(" - ")[1] : "",
        jumat1: res.jumat ? res.jumat?.split(" - ")[0] : "",
        jumat2: res.jumat ? res.jumat?.split(" - ")[1] : "",
        sabtu1: res.sabtu ? res.sabtu?.split(" - ")[0] : "",
        sabtu2: res.sabtu ? res.sabtu?.split(" - ")[1] : "",
        minggu1: res.minggu ? res.minggu?.split(" - ")[0] : "",
        minggu2: res.minggu ? res.minggu?.split(" - ")[1] : "",
      });
    }
  };

  async function submit(e) {
    e.preventDefault();
    Swal.showLoading();
    const res = await action.editData(id,
      {
        namaLengkap: input.namaLengkap,
        poliklinik: input.poliklinik,
        noHp: input.noHp,
        senin: `${input.senin1} - ${input.senin2}`,
        selasa: `${input.selasa1} - ${input.selasa2}`,
        rabu: `${input.rabu1} - ${input.rabu2}`,
        kamis: `${input.kamis1} - ${input.kamis2}`,
        jumat: `${input.jumat1} - ${input.jumat2}`,
        sabtu: `${input.sabtu1} - ${input.sabtu2}`,
        minggu: `${input.minggu1} - ${input.minggu2}`,
      }
    );
    if (res.ok) {
      Swal.close();
      Swal.fire({
        title: "Sukses Edit data",
        timer: 2000,
        icon: "success",
      });
      navigate("/loged/data-dokter");
    } else {
      Swal.fire({
        title: res?.err ?? "Gagal Edit Data",
        timer: 2000,
        icon: "error",
      });
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Paper sx={{ borderRadius: "12px" }}>
      <div style={{ padding: "20px" }}>
        <Typography variant="h5">Edit Dokter</Typography>
      </div>
      <Divider />
      <Container maxWidth="md">
        <div style={{ padding: "20px" }}>
          <form
            onSubmit={submit}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Stack spacing={2}>
              <TextInput
                label="Nama Lengkap"
                onChange={onChangeInput}
                name="namaLengkap"
                value={input.namaLengkap}
                required={true}
              />
              <TextInput
                label="Poliklinik"
                onChange={onChangeInput}
                name="poliklinik"
                value={input.poliklinik}
                required={true}
              />
              <TextInput
                label="Nomor Hp"
                onChange={onChangeInput}
                name="noHp"
                value={input.noHp}
                required={true}
              />
              <Typography fontWeight={600}>Jadwal Praktek</Typography>
              {jadwal.map((e) => (
                <div className="div-input">
                  <div className="label-input capitalize">{e}</div>
                  <Stack
                    width="100%"
                    direction="row"
                    spacing={2}
                    alignItems="center"
                  >
                    <TextField
                      name={`${e}1`}
                      type="time"
                      value={input[`${e}1`]}
                      onChange={onChangeInput}
                      autoComplete="chrome-off"
                      inputProps={{
                        autocomplete: "off",
                      }}
                      size="small"
                      fullWidth
                    />
                    <Typography>Sampai</Typography>
                    <TextField
                      name={`${e}2`}
                      type="time"
                      value={input[`${e}2`]}
                      onChange={onChangeInput}
                      autoComplete="chrome-off"
                      inputProps={{
                        autocomplete: "off",
                      }}
                      size="small"
                      fullWidth
                    />
                  </Stack>
                </div>
              ))}
            </Stack>
            <div style={{ marginTop: "20px", marginLeft: "185px" }}>
              <BtnCus
                size="small"
                variant="contained"
                type="submit"
                color="primary"
                text="Submit"
                style={{ width: "100px" }}
              />
              <BtnCus
                size="small"
                variant="outlined"
                text="Batal"
                style={{ marginLeft: "12px", width: "100px", color: "#f37435" }}
                onClick={() => navigate(-1)}
              />
            </div>
          </form>
        </div>
      </Container>
    </Paper>
  );
}
