import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Layout from '../../Layout/Index';
import { getGuru, getPengguna, getPesertaDidik, getSarpras, getSekolah, getTenagapendidik, setProfil } from '../../store/actions/userAction';

export default function Index() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setProfil())
      dispatch(getSekolah())
      dispatch(getGuru())
      dispatch(getPesertaDidik())
      dispatch(getTenagapendidik())
      dispatch(getSarpras())
      dispatch(getPengguna())
  }, [])
  
  return (
    <div>
      <Layout />
    </div>
  );
}
