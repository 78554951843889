import Paper from '@mui/material/Paper'
import React, { useEffect, useState } from 'react';
import SearchInput from '../../Components/Input/SearchInput';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PaginationCustom from '../../Components/Table/PaginationCustom';
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider';
import Filter from '../Input/Filter';
import BtnCus from '../Btn/BtnCus';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';
// import { opt, optLevel } from '../../helper/opt';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { deleteGuru, deletePengguna, deletePesertaDidik, deleteSarpras, deleteSekolah, deleteTenagapendidik } from '../../store/actions/userAction';

export default function TableCus(props) {
    const profil = useSelector(state => state.data.profil)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [optProv, setOptProv] = useState([])
    const [optKab, setOptKab] = useState([])
    // const data = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,]
    const [anchorEl, setAnchorEl] = useState(null);
    const [idCr, setIdCr] = useState(null)
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    ///value filter
    const [prov, setProv] = useState('')
    const [kab, setKab] = useState('')
    const [level, setLevel] = useState('')

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const handleClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setIdCr(id)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        props.setDataFiltered(props.data)
    }, [props.data])

    useEffect(() => {
        props.setDataSearch(props.dataFiltered)
      }, [props.dataFiltered])
    

  

  

    ///


    function hapus() {
        handleClose()
        Swal.fire({
            title: 'Yakin data ini Dihapus?',
            // text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, hapus',
            cancelButtonText: 'Batal'
        }).then((result) => {
            if (result.isConfirmed) {
                switch (props.hapus) {
                    case 'sekolah':
                        dispatch(deleteSekolah(idCr))
                    case 'guru':
                        dispatch(deleteGuru(idCr))
                    case 'pesertadidik':
                        dispatch(deletePesertaDidik(idCr))
                    case 'tenagapendidik':
                        dispatch(deleteTenagapendidik(idCr))
                    case 'sarpras':
                        dispatch(deleteSarpras(idCr))
                    case 'pengguna':
                        dispatch(deletePengguna(idCr))
                }
            }
        })
    }

    return (
        <div>            
            <TableContainer component={Paper} sx={{ mt: '20px', borderRadius: '20px' }}>
                <Table aria-label="simple table">
                    <TableHead sx={{ bgcolor: '#f2edf5' }}>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 700, fontSize: '14px' }}>No</TableCell>
                            {
                                props.col.map((e, i) => (
                                    <TableCell key={i} align="left" sx={{ fontWeight: 700 }}>{e}</TableCell>
                                ))
                            }
                            <TableCell align="center" sx={{ fontWeight: 700, fontSize: '14px' }}>Aksi</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? props.dataSearch.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                            : props.dataSearch
                        )
                            .map((e, i) => (
                                <TableRow
                                    key={i}
                                // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row" sx={{ fontSize: '14px' }}>
                                        {rowsPerPage > 0?(((page - 1) * rowsPerPage) + i + 1):i+1}
                                    </TableCell>
                                    {
                                        props.row.map((item, i) => (
                                            item === 'sekolah'?
                                            <TableCell key={i} align="left" sx={{ fontSize: '14px' }}>{e.data_sekolah.nama_sekolah}</TableCell>:
                                            <TableCell key={i} align="left" sx={{ fontSize: '14px' }}>{props.hapus === 'pengguna' ? (i === 1 ? e.data_sekolah[item] : e[item]) : e[item]}</TableCell>

                                        ))
                                    }
                                    <TableCell align="center">
                                        <IconButton size='small' onClick={(event) => handleClick(event, e.id)}>
                                            <MoreHorizSharpIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                    <Menu
                        elevation={2}
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                        {
                            props.user || props.sekolah ? null :
                                <>
                                    <MenuItem className='menu-item-setting' onClick={() => navigate(`${props.urlDetail}/${idCr}`)}>
                                        <InfoOutlinedIcon sx={{ mr: '10px' }} /> Detail
                                    </MenuItem>
                                    <Divider sx={{ marginTop: '2px !important', marginBottom: '2px !important' }} />
                                </>
                        }
                        <MenuItem className='menu-item-setting'><DeleteOutlineOutlinedIcon sx={{ mr: '10px' }} onClick={hapus} /> Hapus</MenuItem>
                    </Menu>
                </Table>
                <PaginationCustom handleChangePage={handleChangePage} page={page} setPage={setPage} dataFilter={props.dataSearch} rowsPerPage={rowsPerPage} handleChangeRowsPerPage={handleChangeRowsPerPage} />
            </TableContainer>
        </div>
    )
}
