import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Swal from "sweetalert2";
import TextInput from "./Input/TextInput";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

export default function ModalAddStock(props) {
  const [input, setInput] = useState({
    idObat: "",
    jumlah: "",
    tanggal: "",
  });

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setInput((inp) => ({ ...inp, [name]: value }));
  };

  async function add(e) {
    e.preventDefault();
    Swal.showLoading();
    const res = await props.action.addStock({
      jumlah: input.jumlah,
      tanggal: input.tanggal,
      idObat: props.data.id,
    });
    if (res.ok) {
      await props.action.loadData();
      props.close();
      setInput({
        idObat: "",
        jumlah: "",
        tanggal: "",
      });
      Swal.fire({
        title: "Sukses tambah data",
        timer: 2000,
        icon: "success",
      });
    } else {
      Swal.fire({
        title: res?.err ?? "Gagal Tambah Data",
        timer: 2000,
        icon: "error",
      });
    }
  }

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>
          Tambah Stock Obat
          <Divider sx={{ mt: "12px" }} />
        </DialogTitle>
        <form onSubmit={add}>
          <DialogContent>
            <Stack spacing={2}>
              <TextInput
                label="Jumlah"
                type="number"
                onChange={onChangeInput}
                name="jumlah"
                value={input.jumlah}
                required={true}
              />
              <TextInput
                label="Tanggal"
                type="date"
                onChange={onChangeInput}
                name="tanggal"
                value={input.tanggal}
                required={true}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              sx={{ mr: "12px" }}
              type="submit"
            >
              Submit
            </Button>
            <Button
              variant="outlined"
              color="primary"
              type="button"
              onClick={props.close}
            >
              Batal
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
