import { apiClient } from "../../../../service";

export const viewApi = (filter) => {
  const queryString = Object.keys(filter)
    .map((key) => {
      if (filter[key]) {
        return `${key}=${filter[key]}`;
      }
    })
    .join("&");
  return apiClient.get(`/dokter/view?${queryString}`);
};
export const addApi = (form) => apiClient.post(`/dokter/create`, form);
export const deleteApi = (id) => apiClient.post(`/dokter/delete/${id}`);
export const detailApi = (id) => apiClient.get(`/dokter/view/detail/${id}`);
export const editApi = (id, form) =>
  apiClient.post(`/dokter/update/${id}`, form);
export const viewOptApi = () => apiClient.get(`/dokter/view/options`);
