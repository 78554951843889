import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import TextInput from "../../../Components/Input/TextInput";
import BtnCus from "../../../Components/Btn/BtnCus";
import SelectPasien from "../../../Components/Input/SelectPasien";

export default function TambahAntrian() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function submit(data) {}

  return (
    <Paper sx={{ borderRadius: "12px" }}>
      <div style={{ padding: "20px" }}>
        <Typography variant="h5">Tambah Antrian</Typography>
      </div>
      <Divider />
      <Container maxWidth="md">
        <div style={{ padding: "20px" }}>
          <form
            onSubmit={submit}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Stack spacing={2}>
              <SelectPasien />
            </Stack>
            <div style={{ marginTop: "20px", marginLeft: "185px" }}>
              <BtnCus
                size="small"
                variant="contained"
                type="submit"
                color="primary"
                text="Submit"
                style={{ width: "100px" }}
              />
              <BtnCus
                size="small"
                variant="outlined"
                text="Batal"
                style={{ marginLeft: "12px", width: "100px", color: "#f37435" }}
                onClick={() => navigate(-1)}
              />
            </div>
          </form>
        </div>
      </Container>
    </Paper>
  );
}
