import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Swal from "sweetalert2";
import TextInput from "./Input/TextInput";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { rolesUser } from "../Config/constanta";
import InputSelect from "./Input/InputSelect";

export default function ModalAddUser(props) {
  const [input, setInput] = useState({
    nama: "",
    username: "",
    password: "",
    role: "",
    noHp: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setInput((inp) => ({ ...inp, [name]: value }));
  };

  async function add(e) {
    e.preventDefault();
    Swal.showLoading();
    const res = await props.action.addData(input);
    if (res.ok) {
      await props.action.loadData();
      props.close();
      setInput({
        nama: "",
        username: "",
        password: "",
        role: "",
        noHp: "",
      });
      Swal.fire({
        title: "Sukses tambah data",
        timer: 2000,
        icon: "success",
      });
    } else {
      Swal.fire({
        title: res?.err ?? "Gagal Tambah Data",
        timer: 2000,
        icon: "error",
      });
    }
  }

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>
          Tambah User
          <Divider sx={{ mt: "12px" }} />
        </DialogTitle>
        <form onSubmit={add}>
          <DialogContent>
            <Stack spacing={2}>
              <TextInput
                label="Nama Pengguna"
                onChange={onChangeInput}
                name="nama"
                value={input.nama}
                required={true}
              />
              <TextInput
                label="Username"
                onChange={onChangeInput}
                name="username"
                value={input.username}
                required={true}
              />
              <div className="div-input">
                <div className="label-input">Password</div>
                <TextField
                  size="small"
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  autoComplete="current-password"
                  value={input.password}
                  onChange={onChangeInput}
                  InputProps={{
                    autocomplete: "new-password",
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? (
                            <Visibility className="placeholder" />
                          ) : (
                            <VisibilityOff className="placeholder" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <InputSelect
                label="Role"
                onChange={onChangeInput}
                name="role"
                value={input.role}
                options={rolesUser}
                required={true}
              />
              <TextInput
                label="No Hp"
                onChange={onChangeInput}
                name="noHp"
                value={input.noHp}
                required={true}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button type="submit">Submit</Button>
            <Button type="button" onClick={props.close}>
              Batal
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
