import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Swal from "sweetalert2";
import TextInput from "./Input/TextInput";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import InputSelect from "./Input/InputSelect";
import InputSelectLabel from "./Input/Select";

export default function ModalEditObat(props) {
  const supliers = [];
  const [input, setInput] = useState({
    nama: "",
    jenis: "",
    barkode: "",
    satuan: "",
    hargaJual: 0,
    hargaBeli: 0,
    min: 0,
    idSuplier: 0,
  });

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setInput((inp) => ({ ...inp, [name]: value }));
  };

  useEffect(() => {
    if (props.data) {
      setInput({
        nama: props.data.nama,
        satuan: props.data.satuan,
        jenis: props.data.jenis,
        barkode: props.data.barkode,
        hargaJual: props.data.hargaJual,
        hargaBeli: props.data.hargaBeli,
        min: props.data.min,
        idSuplier: props.data.idSuplier,
      });
    }
  }, [props.data]);

  async function edit(e) {
    e.preventDefault();
    Swal.showLoading();
    const res = await props.action.editData(props.data.id, input);
    if (res.ok) {
      await props.action.loadData();
      props.close();
      setInput({
        nama: "",
        jenis: "",
        barkode: "",
        satuan: "",
        hargaJual: 0,
        hargaBeli: 0,
        min: 0,
        idSuplier: 0,
      });
      Swal.fire({
        title: "Sukses Edit data",
        timer: 2000,
        icon: "success",
      });
    } else {
      Swal.fire({
        title: res?.err ?? "Gagal Edit Data",
        timer: 2000,
        icon: "error",
      });
    }
  }

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>
          Tambah Obat
          <Divider sx={{ mt: "12px" }} />
        </DialogTitle>
        <form onSubmit={edit}>
          <DialogContent>
            <Stack spacing={2}>
              <TextInput
                label="Kode"
                onChange={onChangeInput}
                name="barkode"
                value={input.barkode}
                required={true}
              />
              <TextInput
                label="Nama Obat"
                onChange={onChangeInput}
                name="nama"
                value={input.nama}
                required={true}
              />
              <InputSelectLabel
                label="Jenis"
                options={["Tablet", "Sirup", "Kapsul", "Lainnya"]}
                onChange={onChangeInput}
                name="jenis"
                value={input.jenis}
                required={true}
              />
              <TextInput
                label="Satuan"
                onChange={onChangeInput}
                name="satuan"
                value={input.satuan}
                required={true}
              />
              <TextInput
                label="Stock Minimal"
                type="number"
                onChange={onChangeInput}
                name="min"
                value={input.min}
                required={true}
              />
              <TextInput
                label="Harga Jual"
                type="number"
                onChange={onChangeInput}
                name="hargaJual"
                value={input.hargaJual}
                required={true}
              />
              <TextInput
                label="Harga Beli"
                type="number"
                onChange={onChangeInput}
                name="hargaBeli"
                value={input.hargaBeli}
              />
              <InputSelect
                label="Pilih Suplier"
                onChange={onChangeInput}
                value={input.idSuplier}
                options={supliers ?? []}
                required={true}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button type="submit">Submit</Button>
            <Button type="button" onClick={props.close}>
              Batal
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
