import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BtnCus from "../../../Components/Btn/BtnCus";
import SearchInput from "../../../Components/Input/SearchInput";
import TableCus from "../../../Components/Table/TableCus";

export default function RekamMedis() {
  const navigate = useNavigate()
  const col = [
    "No. RM",
    "Nama Pasien",
    "Tanggal Periksa",
    "Keluhan Utama",
    "Hasil Lab",
    "Diagnosis",
    "Resep",
    "Tindakan",
  ];

  const row = [
    "noRm",
    "namaLengkap",
    "usia",
    "jenisKelamin",
    "alamat",
    "nomorHp",
  ];
  const [searchValue, setSearchValue] = useState("");
  const data = useSelector((state) => state.data.guru);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [flag, setFlag] = useState(false);

  function handleSearch(e) {
    const value = e.target.value;
    let updatedData = [];
    setSearchValue(value);

    if (value.length) {
      updatedData = dataFiltered.filter((item) => {
        const startsWith =
          item.nama.toLowerCase().startsWith(value.toLowerCase()) ||
          item.nuptk.toLowerCase().startsWith(value.toLowerCase()) ||
          item.alamat_jalan.toLowerCase().startsWith(value.toLowerCase());
        const includes =
          item.nama.toLowerCase().includes(value.toLowerCase()) ||
          item.nuptk.toLowerCase().startsWith(value.toLowerCase()) ||
          item.alamat_jalan.toLowerCase().startsWith(value.toLowerCase());
        if (startsWith) {
          return startsWith;
        } else if (!startsWith && includes) {
          return includes;
        } else return null;
      });
      setDataSearch(updatedData);
      setSearchValue(value);
      setFlag(!flag);
    } else {
      setDataSearch(dataFiltered);
    }
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
          <SearchInput value={searchValue} onChange={handleSearch} />
          <div>
            <BtnCus
              variant="contained"
              text='Tambah Rekam Medis'
              style={{ width: "175px" }}
              onClick={() => navigate('/loged/rekam-medis/add')}
            />      
        </div>
      </div>
      <TableCus
        hapus="guru"
        setFlag={setFlag}
        flag={setFlag}
        setDataFiltered={setDataFiltered}
        setDataSearch={setDataSearch}
        dataSearch={dataSearch}
        dataFiltered={dataFiltered}
        data={data}
        handleSearch={handleSearch}
        searchValue={searchValue}
        col={col}
        row={row}
        url="/loged/guru/upload-data-guru"
        urlDetail="/loged/guru/detail-data-guru"
      />
    </>
  );
}
