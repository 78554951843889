import { apiClient } from "../../../../service";

export const viewAppoinmentApi = (filter) => {
  const queryString = Object.keys(filter)
    .map((key) => {
      if (filter[key]) {
        return `${key}=${filter[key]}`;
      }
    })
    .join("&");
  return apiClient.get(`/appoinment/view?${queryString}`);
};
export const addAppoinmentApi = (form) => apiClient.post(`/appoinment/create`, form);
export const deleteAppoinmentApi = (id) => apiClient.post(`/appoinment/delete/${id}`);
export const detailAppoinmentApi = (id) => apiClient.get(`/appoinment/view/detail/${id}`);
export const editAppoinmentApi = (id, form) => apiClient.post(`/appoinment/update/${id}`, form);


export const addRMApi = (form) => apiClient.post(`/rekammedis/create`, form);
export const editRMApi = (id,form) => apiClient.post(`/rekammedis/update/${id}`, form);