import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import { showDateInd } from "../../../helper/convertdate";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import VaccinesOutlinedIcon from "@mui/icons-material/VaccinesOutlined";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import BiotechOutlinedIcon from "@mui/icons-material/BiotechOutlined";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export function Chart() {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        // width={500}
        // height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="pv"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
        <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default function Dashboard() {
  const pasien = useSelector((state) => state.data.pasien);
  const guru = useSelector((state) => state.data.guru);
  const pesertaDidik = useSelector((state) => state.data.pesertaDidik);
  const tenagaPendidik = useSelector((state) => state.data.tenagaPendidik);

  let top = [
    {
      icon: (
        <AccountCircleOutlinedIcon
          sx={{ color: "#f37435", width: "30px", height: "30px" }}
        />
      ),
      label: "Total Pasien",
      val: `${pasien ? pasien.length : 0} Orang`,
    },
    {
      icon: (
        <VaccinesOutlinedIcon
          sx={{ color: "#f37435", width: "30px", height: "30px" }}
        />
      ),
      label: "Obat",
      val: `${pasien ? pasien.length : 0}`,
    },
    {
      icon: (
        <BiotechOutlinedIcon
          sx={{ color: "#f37435", width: "30px", height: "30px" }}
        />
      ),
      label: "Fasilitas Lab",
      val: `${pasien ? pasien.length : 0} Macam`,
    },
    {
      icon: (
        <MedicalServicesOutlinedIcon
          sx={{ color: "#f37435", width: "30px", height: "30px" }}
        />
      ),
      label: "Dokter",
      val: `${pasien ? pasien.length : 0} Orang`,
    },
  ];

  //   console.log(pesertaDidik)
  return (
    <>
      {/* <Paper sx={{ p: "40px", borderRadius: "20px", mb: "20px" }}></Paper> */}
      <Grid container spacing={3}>
        {top.map((e, i) => (
          <Grid item sm={3} key={i}>
            <Paper
              sx={{
                p: "20px",
                borderRadius: "12px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                mr="12px"
                bgcolor="#ecf0eb"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                height="54px"
                width="54px"
              >
                {e.icon}
              </Box>
              <div>
                <Typography sx={{ color: "#999999" }}>{e.label}</Typography>
                <Typography fontSize="16px" fontWeight={600}>
                  {e.val}
                </Typography>
              </div>
            </Paper>
          </Grid>
        ))}
        <Grid item sm={8}>
          <Paper
            sx={{
              p: "20px",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height:'500px'
            }}
          >
            <Typography variant="h6" mb='20px'>Data Transaksi</Typography>
            <Chart />
          </Paper>
        </Grid>
        <Grid item sm={4}>
          <Paper
            sx={{
              p: "20px",
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
            }}
          ></Paper>
        </Grid>
      </Grid>
    </>
  );
}
