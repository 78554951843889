import React from "react";
import NavBar from "./NavBar";
import SideBar from "./SideBar";
import { Outlet } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";

export default function Layout() {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  return (
    <>
      <NavBar handleDrawerToggle={handleDrawerToggle}/>
      <SideBar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle}/>
      <div className="main">
        <Outlet />
      </div>
    </>
  );
}
