import { apiClient } from "../../../../../service";

export const viewSuplierApi = (filter) => {
  const queryString = Object.keys(filter)
    .map((key) => {
      if (filter[key]) {
        return `${key}=${filter[key]}`;
      }
    })
    .join("&");
  return apiClient.get(`/suplier/view?${queryString}`);
};
export const addSuplierApi = (form) => apiClient.post(`/suplier/create`, form);
export const deleteSuplierApi = (id) => apiClient.post(`/suplier/delete/${id}`);
export const detailSuplierApi = (id) => apiClient.get(`/suplier/view/detail/${id}`);
export const viewOptSuplierApi = (id) => apiClient.get(`/suplier/view/options`);
export const editSuplierApi = (id, form) => apiClient.post(`/suplier/update/${id}`, form);
