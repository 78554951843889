import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/system/Box";
import moment from "moment";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Swal from "sweetalert2";
import Searchbar from "../../../Components/Input/Searchbar";
import BtnCus from "../../../Components/Btn/BtnCus";
import PaginationCustom from "../../../Components/PaginationCustom";
import { Avatar, Stack, Tooltip } from "@mui/material";
import { blue, green, orange, red } from "@mui/material/colors";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useAppoinmentStore } from "./store";
import ModalAddAppoinment from "../../../Components/ModalAddAppoinment";
import ModalEditAppoinment from "../../../Components/ModalEditAppoinment";
import ModalAddRM from "../../../Components/ModalAddRM";

export default function Appoinment() {
  const [dataCr, setDataCr] = useState(null);
  const [state, action] = useAppoinmentStore();
  const [open, setOpen] = useState(false);
  const [openRM, setOpenRM] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  useEffect(() => {
    action.loadData();
  }, []);

  async function hapus(id) {
    let modal = await Swal.fire({
      title: "Yakin data ini Dihapus?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, hapus",
      cancelButtonText: "Batal",
    });
    if (modal.isConfirmed) {
      Swal.showLoading();
      const res = await action.deleteData(id);
      if (res) {
        let dat = await action.loadData();
        if (dat) {
          Swal.fire({
            title: "Sukses hapus data",
            timer: 2000,
            icon: "success",
          });
        }
      }
    }
  }

  const edit = (dat) => {
    setOpenEdit(true);
    setDataCr(dat);
  };

  const addRM = (dat) => {
    setOpenRM(true);
    setDataCr(dat);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Searchbar
          placeholder="Cari Nomor Pendaftara"
          action={action}
          state={state}
        />
        <div>
          <BtnCus
            variant="contained"
            text="Tambah"
            onClick={() => setOpen(true)}
          />
        </div>
      </div>

      {/* <Paper sx={{ p: "20px", mt: "20px", borderRadius: "20px" }}> */}
      <TableContainer
        sx={{ mt: "20px", borderRadius: "20px" }}
        component={Paper}
      >
        <Table>
          <TableHead sx={{ bgcolor: "#f2edf5" }}>
            <TableRow>
              {[
                "No",
                "Tanggal",
                "No Pendaftaran",
                "Nama Pasien",
                "Poliklinik",
                "Dokter",
                "Pembayaran",
                "Aksi",
              ].map((e, i) => (
                <TableCell key={i} align="center" sx={{ fontWeight: 700 }}>
                  {e}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!state.data ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  Load data . . .
                </TableCell>
              </TableRow>
            ) : state.data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  No Data
                </TableCell>
              </TableRow>
            ) : (
              state.data.map((row, i) => (
                <TableRow key={i}>
                  <TableCell align="center">
                    {state.page * 10 + (i + 1)}
                  </TableCell>
                  <TableCell align="center">
                    {row.tanggal
                      ? moment(row.tanggal).format("DD-MM-YYYY")
                      : ""}
                  </TableCell>
                  <TableCell align="center">
                    {row.noPendaftaran ?? "-"}
                  </TableCell>
                  <TableCell align="center">
                    {row.Pasien?.namaLengkap ?? ""}
                  </TableCell>
                  <TableCell align="center">{row.poliklinik ?? ""}</TableCell>
                  <TableCell align="center">
                    {row.Dokter?.namaLengkap ?? ""}
                  </TableCell>
                  <TableCell align="center">{row.caraBayar ?? "-"}</TableCell>

                  <TableCell align="center">
                    <Stack direction="row" justifyContent="center" spacing={1}>
                      <Tooltip title="Rekam Medis" placement="top" arrow>
                        <Avatar
                          onClick={() => addRM(row)}
                          sx={{ bgcolor: blue[700], cursor: "pointer" }}
                        >
                          <AssignmentIcon />
                        </Avatar>
                      </Tooltip>
                      <Tooltip title="Invoice" placement="top" arrow>
                        <Avatar
                          // onClick={() => edit(row)}
                          sx={{ bgcolor: green[700], cursor: "pointer" }}
                        >
                          <AttachMoneyIcon />
                        </Avatar>
                      </Tooltip>
                      <Tooltip title="Edit" placement="top" arrow>
                        <Avatar
                          onClick={() => edit(row)}
                          sx={{ bgcolor: orange[500], cursor: "pointer" }}
                        >
                          <EditOutlinedIcon />
                        </Avatar>
                      </Tooltip>
                      <Tooltip title="Hapus" placement="top" arrow>
                        <Avatar
                          onClick={() => hapus(row.id)}
                          sx={{ bgcolor: red[500], cursor: "pointer" }}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </Avatar>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center" my="20px">
        <PaginationCustom action={action} state={state} />
      </Box>
      <ModalAddAppoinment
        open={open}
        close={() => setOpen(false)}
        action={action}
      />
      <ModalAddRM
        open={openRM}
        close={() => setOpenRM(false)}
        action={action}
        data={dataCr}
      />
      <ModalEditAppoinment
        open={openEdit}
        close={() => setOpenEdit(false)}
        action={action}
        data={dataCr}
      />
    </>
  );
}
