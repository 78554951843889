import "./Assets/scss/style.scss";
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import Login from "./Views/Auth/Login";
import Index from "./Views/Pages/Index";
import UserData from "./Views/Pages/User/User";
import { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dashboard from "./Views/Pages/Dashboard/Dashboard";
import Dokter from "./Views/Pages/Dokter/Dokter";
import Pasien from "./Views/Pages/Pasien/Pasien";
import TambahDokter from "./Views/Pages/Dokter/TambahDokter";
import TambahPasien from "./Views/Pages/Pasien/TambahPasien";
import Farmasi from "./Views/Pages/Farmasi/Farmasi";
import Berita from "./Views/Pages/Berita/Berita";
import TambahBerita from "./Views/Pages/Berita/TambahBerita";
import RekamMedis from "./Views/Pages/RekamMedis/RekamMedis";
import TambahRekamMedis from "./Views/Pages/RekamMedis/TambahRekamMedis";
import BuatTagihan from "./Views/Pages/RekamMedis/BuatTagihan";
import Keuangan from "./Views/Pages/Keuangan/Keuangan";
import BuatTagihanBaru from "./Views/Pages/Keuangan/BuatTagihan";
import Pengguna from "./Views/Pages/User/User";
import TambahPengguna from "./Views/Pages/User/TambahUser";
import Antrian from "./Views/Pages/Antrian/Antrian";
import TambahAntrian from "./Views/Pages/Antrian/TambahAntrian";
import EditDokter from "./Views/Pages/Dokter/EditDokter";
import EditPasien from "./Views/Pages/Pasien/EditPasien";
import EditBerita from "./Views/Pages/Berita/EditBerita";
import { useLoginStore } from "./Views/Auth/store";
import Load from "./Components/Load";
import Appoinment from "./Views/Pages/Appoinment/Appoinment";

const pages = [
  { el: <Dokter />, url: "data-dokter" },
  { el: <Appoinment />, url: "appoinment" },
  { el: <TambahDokter />, url: "data-dokter/add" },
  { el: <EditDokter />, url: "data-dokter/edit/:id" },
  { el: <Pasien />, url: "data-pasien" },
  { el: <TambahPasien />, url: "data-pasien/add" },
  { el: <EditPasien />, url: "data-pasien/edit/:id" },
  { el: <UserData />, url: "user-admin" },
  { el: <Dashboard />, url: "dashboard" },
  { el: <Farmasi />, url: "farmasi" },
  { el: <Berita />, url: "berita" },
  { el: <TambahBerita />, url: "berita/add" },
  { el: <EditBerita />, url: "berita/edit/:id" },
  { el: <RekamMedis />, url: "rekam-medis" },
  { el: <TambahRekamMedis />, url: "rekam-medis/add" },
  { el: <BuatTagihan />, url: "rekam-medis/buat-tagihan" },
  { el: <Keuangan />, url: "keuangan" },
  { el: <BuatTagihanBaru />, url: "keuangan/add" },
  { el: <Pengguna />, url: "pengguna" },
  { el: <TambahPengguna />, url: "pengguna/add" },
  { el: <Antrian />, url: "antrian" },
  { el: <TambahAntrian />, url: "antrian/add" },
];

function RequireAuth({ children }) {
  const [state, action] = useLoginStore();
  const location = useLocation();

  return state.isAuthed === true ? (
    children
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
}

function RoleComp({ el, role }) {
  const [state, action] = useLoginStore();

  return role.includes(state.profile.role) ? (
    el
  ) : (
    <Navigate to="/loged/dashboard" replace />
  );
}

function App() {
  const navigate = useNavigate();
  const [state, action] = useLoginStore();

  useEffect(() => {
    action.checkUser();
  }, []);

  return (
    <>
      <Suspense fallback={<Load />}>
        <Routes>
          <Route
            path="/loged"
            element={
              <RequireAuth>
                <Index />
              </RequireAuth>
            }
          >
            {pages.map((page) => (
              <Route path={page.url} element={page.el} />
            ))}
          </Route>
          <Route path="/" element={<Login />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
