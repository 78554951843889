import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/system/Box";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Swal from "sweetalert2";
import Searchbar from "../../../../Components/Input/Searchbar";
import Filter from "../../../../Components/Input/Filter";
import BtnCus from "../../../../Components/Btn/BtnCus";
import { useObatStore } from "./store";
import PaginationCustom from "../../../../Components/PaginationCustom";
import { Avatar, Stack } from "@mui/material";
import ModalAddObat from "../../../../Components/ModalAddObat";
import { blue, green, red } from "@mui/material/colors";
import ModalEditObat from "../../../../Components/ModalEditObat";
import AddIcon from "@mui/icons-material/Add";
import ModalAddStock from "../../../../Components/ModalAddStock";

export default function Obat() {
  const [dataCr, setDataCr] = useState(null);
  const [state, action] = useObatStore();
  const [open, setOpen] = useState(false);
  const [openAddStock, setOpenAddStock] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  useEffect(() => {
    action.loadData();
  }, []);

  async function hapus(id) {
    let modal = await Swal.fire({
      title: "Yakin data ini Dihapus?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, hapus",
      cancelButtonText: "Batal",
    });
    if (modal.isConfirmed) {
      Swal.showLoading();
      const res = await action.deleteData(id);
      if (res) {
        let dat = await action.loadData();
        if (dat) {
          Swal.fire({
            title: "Sukses hapus data",
            timer: 2000,
            icon: "success",
          });
        }
      }
    }
  }

  const edit = (dat) => {
    setOpenEdit(true);
    setDataCr(dat);
  };

  const addStock = (dat) => {
    setOpenAddStock(true);
    setDataCr(dat);
  };

  const handleChangeFilter = (key1, key2, value, reason) => {
    if (reason === "clear") {
      action.updateStore({
        [key1]: "",
        [key2]: "",
      });
    } else {
      action.updateStore({
        [key1]: value.code,
        [key2]: value.label,
      });
    }
  };

  useEffect(() => {
    action.updateStore({
      page: 0,
    });
    action.loadData();
  }, [state.kodeKecamatan, state.kodeKelurahan, state.status]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Searchbar action={action} state={state} />
        <div>
          <BtnCus
            variant="contained"
            text="Tambah Obat"
            onClick={() => setOpen(true)}
          />
        </div>
      </div>

      {/* <Paper sx={{ p: "20px", mt: "20px", borderRadius: "20px" }}> */}
      <TableContainer
        sx={{ mt: "20px", borderRadius: "20px" }}
        component={Paper}
      >
        <Table>
          <TableHead sx={{ bgcolor: "#f2edf5" }}>
            <TableRow>
              {[
                "No",
                "Kode",
                "Nama Obat",
                "Jenis",
                "Satuan",
                "Harga",
                "Suplier",
                "Stock",
                "Aksi",
              ].map((e, i) => (
                <TableCell key={i} align="left" sx={{ fontWeight: 700 }}>
                  {e}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!state.data ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  Load data . . .
                </TableCell>
              </TableRow>
            ) : state.data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  No Data
                </TableCell>
              </TableRow>
            ) : (
              state.data.map((row, i) => (
                <TableRow key={i}>
                  <TableCell>{state.page * 10 + (i + 1)}</TableCell>
                  <TableCell>{row.barkode ?? ""}</TableCell>
                  <TableCell>{row.nama ?? ""}</TableCell>
                  <TableCell>{row.jenis ?? ""}</TableCell>
                  <TableCell> {row.satuan ?? ""}</TableCell>
                  <TableCell>{row.hargaJual ?? ""}</TableCell>
                  <TableCell> {row.Suplier?.nama ?? ""}</TableCell>
                  <TableCell>
                    {(row.stokTotal ?? 0) - (row.terjual ?? 0) ?? 0}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      <Avatar
                        onClick={() => addStock(row)}
                        sx={{ bgcolor: green[500], cursor: "pointer" }}
                      >
                        <AddIcon />
                      </Avatar>
                      <Avatar
                        onClick={() => edit(row)}
                        sx={{ bgcolor: blue[500], cursor: "pointer" }}
                      >
                        <EditOutlinedIcon />
                      </Avatar>
                      <Avatar
                        onClick={() => hapus(row.id)}
                        sx={{ bgcolor: red[500], cursor: "pointer" }}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </Avatar>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center" my="20px">
        <PaginationCustom action={action} state={state} />
      </Box>
      <ModalAddObat open={open} close={() => setOpen(false)} action={action} />
      <ModalAddStock
        open={openAddStock}
        close={() => setOpenAddStock(false)}
        action={action}
        data={dataCr}
      />
      <ModalEditObat
        open={openEdit}
        close={() => setOpenEdit(false)}
        action={action}
        data={dataCr}
      />
    </>
  );
}
