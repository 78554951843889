import { Divider, IconButton, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import TextInput from "../../../Components/Input/TextInput";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import LihatTagihanKhusus from "./LihatTagihanKhusus";

const headTable = [
  "No",
  "Keterangan",
  "Biaya Per Satuan",
  "Kuantitas",
  "Sub Total",
];

export default function BuatTagihan() {
  const pasien = [
    { label: "Tanggal", value: "0909" },
    { label: "No. RM", value: "0909" },
    { label: "Nama", value: "0909" },
    { label: "Alamat", value: "Lorem ipsum dolor" },
  ];

  return (
    <div style={{ display: "flex", flexDirection:'column', alignItems: "center" }}>
      <Paper sx={{ width: "1000px", p: "20px", borderRadius: "20px", mb:'20px' }}>
        <div style={{ paddingBottom: "20px" }}>
          <Typography variant="h5">Buat Tagihan Khusus</Typography>
        </div>
        <Divider sx={{ mb: "20px" }} />
        <div className="div-input">
          {
            <Box display="flex" width="100%">
              <Box width="50%">
                <TextInput
                  placeholder="Keterangan"
                  style={{ width: 0, marginLeft: "10px" }}
                />
              </Box>
              <Box width="50%">
                <TextInput
                  placeholder="Biaya Per Satuan"
                  style={{ width: 0, marginLeft: "10px" }}
                />
              </Box>
              <Box width="50%">
                <TextInput
                  placeholder="Kuantitas"
                  style={{ width: 0, marginLeft: "10px" }}
                />
              </Box>
              <Box width="50%">
                <TextInput
                  disabled={true}
                  placeholder="Jumlah"
                  style={{ width: 0, marginLeft: "10px" }}
                />
              </Box>
              <IconButton color="secondary" size="small">
                <AddCircleIcon fontSize="large" />
              </IconButton>
              <IconButton color="error" size="small">
                <RemoveCircleIcon fontSize="large" />
              </IconButton>
            </Box>
          }
        </div>
      </Paper>
      <LihatTagihanKhusus/>
    </div>
  );
}
