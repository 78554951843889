import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Swal from "sweetalert2";
import TextInput from "./Input/TextInput";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { rolesUser } from "../Config/constanta";
import InputSelect from "./Input/InputSelect";

export default function ModalEditUser(props) {
  const [input, setInput] = useState({
    nama: "",
    username: "",
    role: "",
    noHp: "",
  });

  useEffect(() => {
    if (props.data) {
      setInput({
        nama: props.data.nama,
        username: props.data.username,
        role: props.data.role,
        noHp: props.data.noHp,
      });
    } else {
      setInput({
        nama: "",
        username: "",
        role: "",
        noHp: "",
      });
    }
  }, [props.open]);

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setInput((inp) => ({ ...inp, [name]: value }));
  };

  async function edit(e) {
    e.preventDefault();
    Swal.showLoading();
    const res = await props.action.editData(props.data.id, input);
    if (res.ok) {
      await props.action.loadData();
      props.close();
      setInput({
        nama: "",
        password: "",
        role: "",
        noHp: "",
      });
      Swal.fire({
        title: "Sukses edit data",
        timer: 2000,
        icon: "success",
      });
    } else {
      Swal.fire({
        title: res?.err ?? "Gagal edit Data",
        timer: 2000,
        icon: "error",
      });
    }
  }

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>
          Edit User
          <Divider sx={{ mt: "12px" }} />
        </DialogTitle>
        <form onSubmit={edit}>
          <DialogContent>
            <Stack spacing={2}>
              <TextInput
                label="Nama Pengguna"
                onChange={onChangeInput}
                name="nama"
                value={input.nama}
                required={true}
              />
              <TextInput
                label="Username"
                onChange={onChangeInput}
                name="username"
                value={input.username}
                required={true}
              />

              <InputSelect
                label="Role"
                onChange={onChangeInput}
                name="role"
                value={input.role}
                options={rolesUser}
                required={true}
              />
              <TextInput
                label="No Hp"
                onChange={onChangeInput}
                name="noHp"
                value={input.noHp}
                required={true}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button type="submit">Submit</Button>
            <Button type="button" onClick={props.close}>
              Batal
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
