import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/system/Box";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Searchbar from "../../../Components/Input/Searchbar";
import Filter from "../../../Components/Input/Filter";
import BtnCus from "../../../Components/Btn/BtnCus";
import { usePasienStore } from "./store";
import PaginationCustom from "../../../Components/PaginationCustom";
import { Button } from "@mui/material";

export default function Pengguna() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [idCr, setIdCr] = useState(null);
  const [state, action] = usePasienStore();

  useEffect(() => {
    action.loadData();
  }, []);

  async function hapus() {
    handleClose();
    let modal = await Swal.fire({
      title: "Yakin data ini Dihapus?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, hapus",
      cancelButtonText: "Batal",
    });
    if (modal.isConfirmed) {
      Swal.showLoading();
      const res = await action.deleteData(idCr);
      if (res) {
        let dat = await action.loadData();
        if (dat) {
          Swal.fire({
            title: "Sukses hapus data",
            timer: 2000,
            icon: "success",
          });
        }
      }
    }
  }

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setIdCr(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeFilter = (key1, key2, value, reason) => {
    if (reason === "clear") {
      action.updateStore({
        [key1]: "",
        [key2]: "",
      });
    } else {
      action.updateStore({
        [key1]: value.code,
        [key2]: value.label,
      });
    }
  };

  // useEffect(() => {
  //   action.updateStore({
  //     page: 0,
  //   });
  //   action.loadData();
  // }, [state.kodeKecamatan, state.kodeKelurahan, state.status]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Searchbar action={action} state={state} />
        <div>
          <BtnCus
            variant="contained"
            text="Tambah Pasien"
            onClick={() => navigate("/loged/data-pasien/add")}
          />
        </div>
      </div>

      {/* <Paper sx={{ p: "20px", mt: "20px", borderRadius: "20px" }}> */}
      <TableContainer
        sx={{ mt: "20px", borderRadius: "20px" }}
        component={Paper}
      >
        <Table>
          <TableHead sx={{ bgcolor: "#f2edf5" }}>
            <TableRow>
              {[
                "No",
                "NIK",
                "No KK",
                "Nama Lengkap",
                "Jenis Kelamin",
                "Tanggal Lahir",
                "Alamat",
                "No Hp",
                "Aksi",
              ].map((e, i) => (
                <TableCell key={i} align="left" sx={{ fontWeight: 700 }}>
                  {e}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!state.data ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  Load data . . .
                </TableCell>
              </TableRow>
            ) : state.data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  No Data
                </TableCell>
              </TableRow>
            ) : (
              state.data.map((row, i) => (
                <TableRow key={i}>
                   <TableCell>{state.page * 10 + (i + 1)}</TableCell>
                  <TableCell>{row.nik ?? ""}</TableCell>
                  <TableCell>{row.noKk ?? ""}</TableCell>
                  <TableCell>{row.namaLengkap ?? ""}</TableCell>
                  <TableCell> {row.jenisKelamin ?? ""}</TableCell>
                  <TableCell>{row.tanggalLahir ?? ""}</TableCell>
                  <TableCell>{row.alamat ?? ""}</TableCell>
                  <TableCell> {row.noHp ?? ""}</TableCell>
                 
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={(event) => handleClick(event, row.id)}
                    >
                      <MoreHorizSharpIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          <Menu
            elevation={2}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MenuItem
              className="menu-item-setting"
              // onClick={() => navigate(`${pathname}/edit/${idCr}`)}
            >
              <AssignmentIcon sx={{ mr: "10px" }} /> Data Rekam Medis
            </MenuItem>
            <Divider
              sx={{
                marginTop: "2px !important",
                marginBottom: "2px !important",
              }}
            />
             <MenuItem
              className="menu-item-setting"
              onClick={() => navigate(`${pathname}/edit/${idCr}`)}
            >
              <EditOutlinedIcon sx={{ mr: "10px" }} /> Edit
            </MenuItem>
            <Divider
              sx={{
                marginTop: "2px !important",
                marginBottom: "2px !important",
              }}
            />
            <MenuItem className="menu-item-setting" onClick={hapus}>
              <DeleteOutlineOutlinedIcon sx={{ mr: "10px" }} />
              Hapus
            </MenuItem>
          </Menu>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center" my="20px">
        <PaginationCustom action={action} state={state} />
      </Box>
      {/* </Paper> */}
    </>
  );
}
