import React from "react";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dLogout } from "../store/actions/userAction";
import MenuIcon from "@mui/icons-material/Menu";
import { useLoginStore } from "../Views/Auth/store";

const menus = [
  { lab: "Dashboard", url: "/loged/dashboard" },
  { lab: "Peserta Didik", url: "/loged/peserta-didik" },
  { lab: "Guru", url: "/loged/guru" },
  { lab: "Tenaga Pendidik", url: "/loged/tenaga-pendidik" },
  { lab: "Sarpras", url: "/loged/sarana-prasarana" },
  { lab: "Sekolah", url: "/loged/sekolah" },
  { lab: "Pengguna", url: "/loged/user-admin" },
];

export default function NavBar({ handleDrawerToggle }) {
  const [state, action] = useLoginStore();
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  async function logout(e) {
    e.preventDefault();
    const out = await action.logout();
    if (out) {
      navigate("/");
      window.location.reload();
    }
  }

  return (
    <>
      <AppBar
        elevation={0}
        position="static"
        sx={{
          width: `calc(100% - 230px)`,
          ml: { xs: "0px", md: `230px` },
          backgroundColor: "white",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* <div>logo</div> */}
            <Box flexGrow={1}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ display: { sm: "none" } }}
              >
                <MenuIcon color="primary" />
              </IconButton>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "flex" } }}>
              <Typography variant="h5">Dashboard</Typography>
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
              }}
            >
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" />
                </IconButton>
              </Tooltip>
              <Typography sx={{ ml: "10px" }}>
              {state?.profile?.name}
              </Typography>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {/* {settings.map((setting) => ( */}
                <MenuItem onClick={logout} className="menu-item-setting">
                  Logout
                </MenuItem>
                {/* ))} */}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
