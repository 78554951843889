import React, { useState } from "react";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Swal from "sweetalert2";
import { useUserStore } from "../Views/Pages/User/store";

export default function GantiPassword(props) {
  const [state, action] = useUserStore();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [input, setInput] = useState({ newPassword: "", confirmPassword: "" });

  function onChangeInput(event) {
    let { name, value } = event.target;
    setInput({
      ...input,
      [name]: value,
    });
  }

  const submitPass = async () => {
    Swal.showLoading();
    const res = await action.gantiPass(props.data.id, input);
    if (res) {
      Swal.close();
      Swal.fire({
        title: "Sukses ganti password",
        timer: 2000,
        icon: "success",
      });
    } else {
      Swal.close();
      Swal.fire({
        title: "Gagal ganti password",
        timer: 2000,
        icon: "error",
      });
    }
  };

  function submit(event) {
    event.preventDefault();
    if (input.password === input.ulangiPassword) {
      props.close();
      submitPass();
    } else {
      Swal.fire({ icon: "error", title: "Password Belum Sesuai" });
    }
  }

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>Ganti Password</DialogTitle>
        <DialogContent>
          {/* <div style={{ marginTop: "16px" }}>
            <TextField
              size="small"
              label="Password Lama"
              fullWidth
              type={showPasswordOld ? "text" : "password"}
              id="oldPassword"
              name="oldPassword"
              autoComplete="current-password"
              value={input.oldPassword}
              onChange={onChangeInput}
              InputProps={{
                autocomplete: "new-password",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPasswordOld(!showPasswordOld)}
                    >
                      {showPasswordOld ? (
                        <Visibility className="placeholder" />
                      ) : (
                        <VisibilityOff className="placeholder" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div> */}
          <div style={{ marginTop: "16px" }}>
            <TextField
              size="small"
              label="Password Baru"
              fullWidth
              type={showPassword ? "text" : "password"}
              id="newPassword"
              name="newPassword"
              autoComplete="current-password"
              value={input.newPassword}
              onChange={onChangeInput}
              InputProps={{
                autocomplete: "new-password",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <Visibility className="placeholder" />
                      ) : (
                        <VisibilityOff className="placeholder" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div style={{ marginTop: "16px" }}>
            <TextField
              size="small"
              label="Ulangi Password Baru"
              fullWidth
              type={showPasswordRepeat ? "text" : "password"}
              id="password"
              error={input.password !== input.ulangiPassword}
              helperText={
                input.ulangiPassword && input.password !== input.ulangiPassword
                  ? "Password tidak sesuai"
                  : null
              }
              autoComplete="current-password"
              name="confirmPassword"
              value={input.confirmPassword}
              onChange={onChangeInput}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
                    >
                      {showPasswordRepeat ? (
                        <Visibility className="placeholder" />
                      ) : (
                        <VisibilityOff className="placeholder" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={submit}>Submit</Button>
          <Button onClick={props.close}>Batal</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
