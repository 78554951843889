import { Divider, Paper, Typography } from "@mui/material";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const headTable = [
  "No",
  "Keterangan",
  "Biaya Per Satuan",
  "Kuantitas",
  "Sub Total",
];

export default function LihatTagihanKhusus() {

  return (
      <Paper sx={{ width: "1000px", p: "20px", borderRadius:'20px' }}>
        <Typography variant="h4" textAlign="center" mb='20px'>
          Rincian Biaya
        </Typography>
        <Divider sx={{mb:'20px', borderWidth:'2px'}}/>
        <Table>
          <TableHead>
            <TableRow sx={{bgcolor: '#e0dede'}}>
              {headTable.map((e) => (
                <TableCell sx={{fontWeight: 600}}>{e}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {[1, 2, 3].map((e) => (
              <TableRow>
                {headTable.map((e) => (
                  <TableCell>{e}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          <TableHead>
            <TableRow >
              <TableCell sx={{fontWeight: 600}} colSpan={4} align='center'>Total</TableCell>
              <TableCell sx={{fontWeight: 600}}>Rp. 200.000,-</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell colSpan={4} align='center'></TableCell>
              <TableCell align="center">
                  <Typography>Serang, .....................  2022</Typography>
                  <Typography mt='80px'>Petugas</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </Paper>
  );
}
