import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Lab from "./Lab/Lab";
import Obat from "./Obat/Obat";
import Suplier from "./Suplier/Suplier";

export default function Farmasi() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Paper sx={{ p: "12px", mb: "20px", borderRadius: "8px" }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            sx={{ textTransform: "none", fontWeight: 600, fontSize: "18px" }}
            label="Fasilitas Lab"
            value={0}
          />
          <Tab
            sx={{ textTransform: "none", fontWeight: 600, fontSize: "18px" }}
            label="Data Obat"
            value={1}
          />
           <Tab
            sx={{ textTransform: "none", fontWeight: 600, fontSize: "18px" }}
            label="Suplier Obat"
            value={2}
          />
        </Tabs>
      </Paper>
      {value === 0 && <Lab />}
      {value === 1 && <Obat />}
      {value === 2 && <Suplier />}
    </>
  );
}
