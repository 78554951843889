import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import TextInput from "../../../Components/Input/TextInput";
import BtnCus from "../../../Components/Btn/BtnCus";
import InputSelect from "../../../Components/Input/Select";
import SelectPasien from "../../../Components/Input/SelectPasien";
import { Box } from "@mui/system";
import { IconButton, Select } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

export default function TambahRekamMedis() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function submit(data) {}

  return (
    <Paper sx={{ borderRadius: "12px" }}>
      <div style={{ padding: "20px" }}>
        <Typography variant="h5">Tambah Rekam Medis</Typography>
      </div>
      <Divider />
      <Container maxWidth="md">
        <div style={{ padding: "20px" }}>
          <form
            onSubmit={submit}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Stack spacing={2}>
              <SelectPasien />
              <InputSelect label="Pilih Dokter" />
              <TextInput label="Keluhan Utama" />
              <TextInput label="Anamnesis" />
              <TextInput label="Pemeriksaan Fisik" />
              <div className="div-input">
                <div className="label-input">Pemeriksaan Penunjang</div>
                {
                  <Box display="flex" width="100%">
                    <Box width="50%">
                      <InputSelect placeholder='Pilih Lab' style={{ width: 0 }} />
                    </Box>
                    <Box width="50%">
                      <TextInput placeholder='Hasil' style={{ width: 0, marginLeft: "10px" }} />
                    </Box>
                    <IconButton color="secondary" size="small">
                      <AddCircleIcon fontSize="large" />
                    </IconButton>
                    <IconButton color="error" size="small">
                      <RemoveCircleIcon fontSize="large" />
                    </IconButton>
                  </Box>
                }
              </div>
              <TextInput label="Diagnosis" />
              <div className="div-input">
                <div className="label-input">Resep</div>
                {
                  <Box display="flex" width="100%">
                    <Box width="40%">
                      <InputSelect placeholder='Pilih Obat' style={{ width: 0 }} />
                    </Box>
                    <Box width="20%">
                      <TextInput placeholder='Jumlah' style={{ width: 0, marginLeft: "10px" }} />
                    </Box>
                    <Box width="40%">
                      <TextInput placeholder='Aturan' style={{ width: 0, marginLeft: "10px" }} />
                    </Box>
                    <IconButton color="secondary" size="small">
                      <AddCircleIcon fontSize="large" />
                    </IconButton>
                    <IconButton color="error" size="small">
                      <RemoveCircleIcon fontSize="large" />
                    </IconButton>
                  </Box>
                }
              </div>
              <TextInput label="Tindakan" />
            </Stack>
            <div style={{ marginTop: "20px", marginLeft: "185px" }}>
              <BtnCus
                size="small"
                variant="contained"
                type="submit"
                color="primary"
                text="Submit"
                style={{ width: "100px" }}
              />
              <BtnCus
                size="small"
                variant="outlined"
                text="Batal"
                style={{ marginLeft: "12px", width: "100px", color: "#f37435" }}
                onClick={() => navigate(-1)}
              />
            </div>
          </form>
        </div>
      </Container>
    </Paper>
  );
}
