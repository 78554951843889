import React from "react";

//comp
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

//icon
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import AssuredWorkloadOutlinedIcon from "@mui/icons-material/AssuredWorkloadOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import VaccinesOutlinedIcon from "@mui/icons-material/VaccinesOutlined";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PointOfSaleOutlinedIcon from "@mui/icons-material/PointOfSaleOutlined";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";
import AccessibleOutlinedIcon from '@mui/icons-material/AccessibleOutlined';

import { NavLink, useLocation } from "react-router-dom";
import logo from "../Assets/images/logo.png";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";

const menus = [
  {
    lab: "Dashboard",
    url: "/loged/dashboard",
    icon: <DashboardOutlinedIcon />,
  },
  {
    lab: "Data Dokter",
    url: "/loged/data-dokter",
    icon: <MedicalServicesOutlinedIcon />,
  },
  {
    lab: "Data Pasien",
    url: "/loged/data-pasien",
    icon: <AccessibleOutlinedIcon/>,
  },
  {
    lab: "Appoinment",
    url: "/loged/appoinment",
    icon: <EventOutlinedIcon />,
  },
  // {
  //   lab: "Rekam Medis",
  //   url: "/loged/rekam-medis",
  //   icon: <ListAltOutlinedIcon />,
  // },
  { lab: "Farmasi", url: "/loged/farmasi", icon: <VaccinesOutlinedIcon /> },
  {
    lab: "Berita",
    url: "/loged/berita",
    icon: <NewspaperOutlinedIcon />,
  },
  {
    lab: "Keuangan",
    url: "/loged/keuangan",
    icon: <PointOfSaleOutlinedIcon />,
  },
  {
    lab: "Antrian",
    url: "/loged/antrian",
    icon: <QueuePlayNextIcon />,
  },
  {
    lab: "Pengguna",
    url: "/loged/pengguna",
    icon: <AccountCircleOutlinedIcon />,
  },
];

const menusSeko = [
  {
    lab: "Dashboard",
    url: "/loged/dashboard",
    icon: <DashboardOutlinedIcon />,
  },
  { lab: "Sekolah", url: "/loged/sekolah", icon: <SchoolOutlinedIcon /> },
  {
    lab: "Peserta Didik",
    url: "/loged/peserta-didik",
    icon: <BadgeOutlinedIcon />,
  },
  { lab: "Guru", url: "/loged/guru", icon: <GroupOutlinedIcon /> },
  {
    lab: "Tenaga Pendidik",
    url: "/loged/tenaga-pendidik",
    icon: <ManageAccountsOutlinedIcon />,
  },
  {
    lab: "Sarpras",
    url: "/loged/sarana-prasarana",
    icon: <AssuredWorkloadOutlinedIcon />,
  },
];

const useStyles = makeStyles((theme) => ({
  listItemSelected: {
    backgroundColor: "#f37435 !important",
    color: "white",
    borderRadius: "12px",
    "& .MuiListItemIcon-root": {
      color: "white",
    },
  },
}));

export default function SideBar({ mobileOpen, handleDrawerToggle }) {
  const location = useLocation();
  const classes = useStyles();
  const profil = useSelector((state) => state.data.profil);

  const drawer = (
    <div>
      <Box textAlign="center" pt="20px" pb="10px">
        <img src={logo} width="180px" alt="logo" />
        <Typography mt="6px" fontWeight={600}>
          <span>Sistem Manajemen</span>
          <br />
          Apotik dan Klinik Anggrek
        </Typography>
      </Box>
      <Divider />
      <List sx={{ p: "20px", pt: "0px" }}>
        {(profil && profil.role === "admin_sekolah" ? menusSeko : menus).map(
          (menu, i) => (
            <div key={i}>
              <ListItem
                classes={{ selected: classes.listItemSelected }}
                button
                component={NavLink}
                to={menu.url}
                selected={location.pathname.includes(menu.url)}
                sx={{
                  my: "8px",
                  "&:hover": {
                    borderRadius: "12px",
                    backgroundColor: "#f37435 !important",
                    color: "white !important",
                    "& .MuiListItemIcon-root": {
                      color: "white",
                    },
                  },
                }}
              >
                <ListItemIcon sx={{ minWidth: "40px" }}>
                  {menu.icon}
                </ListItemIcon>
                <ListItemText sx={{ ml: 0 }} primary={menu.lab} />
              </ListItem>
            </div>
          )
        )}
      </List>
    </div>
  );

  return (
    <>
      <Drawer
        sx={{
          display: { xs: "block", sm: "none" },
          width: "230px",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "230px",
            boxSizing: "border-box",
            border: "none",
            position: "absolute",
          },
        }}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        anchor="left"
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          width: "230px",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "230px",
            boxSizing: "border-box",
            border: "none",

            position: "absolute",
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </>
  );
}
