const initialState = {
    pasien:null,
    pesertaDidik: [],
    guru: [],
    tenagaPendidik: [],
    sarpras: [],
    pengguna: [],
    profil: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "GETPROFIL":
            return { ...state, profil: action.payload }
        case "GETGURU":
            return { ...state, guru: action.payload }
        case "GETPESERTADIDIK":
            return { ...state, pesertaDidik: action.payload }
        case "GETTENAGAPENDIDIK":
            return { ...state, tenagaPendidik: action.payload }
        case "GETSARPRAS":
            return { ...state, sarpras: action.payload }
        case "GETSEKOLAH":
            return { ...state, sekolah: action.payload }
        case "GETPENGGUNA":
            return { ...state, pengguna: action.payload }
        case "ADDSEKOLAH":
            return { ...state, sekolah: [...state.sekolah, action.payload] }
        case "ADDPENGGUNA":
            let dat = action.payload
            let newUser = {data_sekolah: state.sekolah.filter(e => e.id === dat.sekolah_id)[0], role: dat.role, nama: dat. nama, id: dat.id, username: dat.username}
            return { ...state, pengguna: [...state.pengguna, newUser] }
        case "DELETESEKOLAH":
            let temp = state.sekolah.filter(e => e.id !== action.payload)
            return { ...state, sekolah: temp }
        case "DELETEPENGGUNA":
            let tempPengguna = state.pengguna.filter(e => e.id !== action.payload)
            return { ...state, pengguna: tempPengguna }
        case "DELETEGURU":
            let tempGuru = state.guru.filter(e => e.id !== action.payload)
            return { ...state, guru: tempGuru }
        case "DELETEPESERTADIDIK":
            let tempPeserdidik = state.pesertaDidik.filter(e => e.id !== action.payload)
            return { ...state, pesertaDidik: tempPeserdidik }
        case "DELETETENAGAPENDIDIK":
            let tempTenagaPendidik = state.tenagaPendidik.filter(e => e.id !== action.payload)
            return { ...state, tenagaPendidik: tempTenagaPendidik }
        case "DELETESARPRAS":
            let tempSarpras = state.sarpras.filter(e => e.id !== action.payload)
            return { ...state, sarpras: tempSarpras }
        default:
            return state

    }
}

