import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export default function InputSelect({
  label,
  onChange,
  name,
  value,
  readOnly,
  required,
  options,
}) {
  return (
    <div className="div-input">
      <div className="label-input">{label}</div>
      <Select
        value={value}
        name={name}
        readOnly={readOnly}
        onChange={onChange}
        required={required}
        fullWidth
        size="small"
        renderValue={
          value !== "" ? undefined : () => <div>Pilih</div>
        }
      >
        {options &&
          options.map((e, i) => (
            <MenuItem key={i} value={name === "jenisKelamin" ? e : e.id}>
              {name === "jenisKelamin" ? e : e.label}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
}
