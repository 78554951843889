export const rolesUser = [
  { id: 1, label: "Superadmin" },
  { id: 2, label: "Admin" },
  { id: 3, label: "Dokter" },
  { id: 4, label: "Perawat" },
];

export const rolesRelawan = [
  // { code: 4, label: "Koor Kabupaten" },
  // { code: 5, label: "Koor Kecamatan" },
  { code: 6, label: "Tim Desa" },
  { code: 7, label: "Relawan RW" },
];

export const statuss = [
  { code: 0, label: "Belum Diverifikasi" },
  { code: 1, label: "Terverifikasi" },
  { code: 2, label: "Ditolak" },
];

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Mei",
  "Jun",
  "Jul",
  "Agu",
  "Sep",
  "Okt",
  "Nov",
  "Des",
];

export const tpss = () => {
  let res = [];
  for (let i = 1; i <= 99; i++) {
    res.push({ code: `${i}`, label: i < 10 ? `0${i}` : `${i}` });
  }
  return res;
};
