import { apiClient } from "../../../../service";

export const viewPasienApi = (filter) => {
  const queryString = Object.keys(filter)
    .map((key) => {
      if (filter[key]) {
        return `${key}=${filter[key]}`;
      }
    })
    .join("&");
  return apiClient.get(`/pasien/view?${queryString}`);
};
export const viewOptPasienApi = (filter) => {
  const queryString = Object.keys(filter)
    .map((key) => {
      if (filter[key]) {
        return `${key}=${filter[key]}`;
      }
    })
    .join("&");
  return apiClient.get(`/pasien/view/options?${queryString}`);
};
export const addPasienApi = (form) => apiClient.post(`/pasien/create`, form);
export const deletePasienApi = (id) => apiClient.post(`/pasien/delete/${id}`);
export const detailPasienApi = (id) => apiClient.get(`/pasien/view/detail/${id}`);
export const editPasienApi = (id, form) => apiClient.post(`/pasien/update/${id}`, form);
