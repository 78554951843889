import { Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BtnCus from "../../../Components/Btn/BtnCus";
import SearchInput from "../../../Components/Input/SearchInput";
import TableCus from "../../../Components/Table/TableCus";

export default function Antrian() {
  const navigate = useNavigate();
  const col = ["Hari, Tanggal", "Poliklinik", "Nama Pasien", "Nomor Antrian"];
  const row = ["nama", "sekolah", "nuptk", "alamat_jalan"];
  const [searchValue, setSearchValue] = useState("");
  const data = useSelector((state) => state.data.guru);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [flag, setFlag] = useState(false);

  function handleSearch(e) {
    const value = e.target.value;
    let updatedData = [];
    setSearchValue(value);

    if (value.length) {
      updatedData = dataFiltered.filter((item) => {
        const startsWith =
          item.nama.toLowerCase().startsWith(value.toLowerCase()) ||
          item.nuptk.toLowerCase().startsWith(value.toLowerCase()) ||
          item.alamat_jalan.toLowerCase().startsWith(value.toLowerCase());
        const includes =
          item.nama.toLowerCase().includes(value.toLowerCase()) ||
          item.nuptk.toLowerCase().startsWith(value.toLowerCase()) ||
          item.alamat_jalan.toLowerCase().startsWith(value.toLowerCase());
        if (startsWith) {
          return startsWith;
        } else if (!startsWith && includes) {
          return includes;
        } else return null;
      });
      setDataSearch(updatedData);
      setSearchValue(value);
      setFlag(!flag);
    } else {
      setDataSearch(dataFiltered);
    }
  }

  return (
    <>
      <Grid container spacing={3} py="20px">
        {["Poli Umum", "Poli Gigi", "Fisioterapi", "Poli Anak"].map((e) => (
          <Grid item xs={3}>
            <Paper sx={{ p: "20px" }}>
              <Typography fontWeight={600} variant="h6">{e}</Typography>
              <Typography fontWeight={900} variant="h2">001</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <SearchInput value={searchValue} onChange={handleSearch} />
        </div>
        <div>
          <BtnCus
            variant="contained"
            text="Tambah Antrian"
            style={{ width: "155px" }}
            onClick={() => navigate("/loged/antrian/add")}
          />
        </div>
      </div>
      <TableCus
        hapus="guru"
        setFlag={setFlag}
        flag={setFlag}
        setDataFiltered={setDataFiltered}
        setDataSearch={setDataSearch}
        dataSearch={dataSearch}
        dataFiltered={dataFiltered}
        data={data}
        handleSearch={handleSearch}
        searchValue={searchValue}
        col={col}
        row={row}
        url="/loged/guru/upload-data-guru"
        urlDetail="/loged/guru/detail-data-guru"
      />
    </>
  );
}
