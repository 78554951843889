import React, { useState } from "react";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

export default function Searchbar({
  placeholder,
  state,
  action,
  handleChange,
  handleSubmit,
  value,
}) {
  const handleSubmitSearch = (event) => {
    event.preventDefault();
    action.updateStore({
      skip: 0,
    });
    action.loadData();
  };

  const handlechangeSearch = (event) => {
    action.updateStore({
      search: event.target.value,
    });
  };

  return (
    <Paper
      component="form"
      elevation={1}
      sx={{
        p: "2px 6px",
        display: "flex",
        alignItems: "center",
        width: 200,
        borderRadius: 10,
        height: "40px",
      }}
      onSubmit={handleSubmit ?? handleSubmitSearch}
    >
      <InputBase
        sx={{ ml: 2, flex: 1 }}
        placeholder={placeholder ?? "Cari Data"}
        value={value ? value : state?.search ?? ""}
        onChange={handleChange ?? handlechangeSearch}
        inputProps={{ "aria-label": "search google maps" }}
      />
      <IconButton type="submit" aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
    // <Box
    //   component="form"
    //   sx={{
    //     p: "4px 4px",
    //     display: "flex",
    //     borderRadius: "12px",
    //     alignItems: "center",
    //     minWidth: 200,
    //     border: "1px solid rgba(224, 224, 224, 1)",
    //   }}
    //   onSubmit={handleSubmit ?? handleSubmitSearch}
    // >
    //   <InputBase
    //     size="small"
    //     value={value ? value : state?.search ?? ""}
    //     onChange={handleChange ?? handlechangeSearch}
    //     sx={{ ml: 1, flex: 1 }}
    //     placeholder={placeholder ?? "Cari Data"}
    //     inputProps={{ "aria-label": "search google maps" }}
    //   />
    //   <IconButton type="submit" sx={{ px: "10px" }} aria-label="search">
    //     <SearchIcon />
    //   </IconButton>
    // </Box>
  );
}
