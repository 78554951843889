import { apiClient } from "../../../../service";

export const viewArtikelApi = (filter) => {
  const queryString = Object.keys(filter)
    .map((key) => {
      if (filter[key]) {
        return `${key}=${filter[key]}`;
      }
    })
    .join("&");
  return apiClient.get(`/artikel/view?${queryString}`);
};
export const addArtikelApi = (form) => apiClient.post(`/artikel/create`, form);
export const deleteArtikelApi = (id) => apiClient.post(`/artikel/delete/${id}`);
export const detailArtikelApi = (id) => apiClient.get(`/artikel/view/detail/${id}`);
export const editArtikelApi = (id, form) => apiClient.post(`/artikel/update/${id}`, form);
