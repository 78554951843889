import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/system/Box";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Searchbar from "../../../../Components/Input/Searchbar";
import BtnCus from "../../../../Components/Btn/BtnCus";
import PaginationCustom from "../../../../Components/PaginationCustom";
import { useSuplierStore } from "./store";
import ModalAddSuplier from "../../../../Components/ModalAddSuplier";
import { Avatar, Button, Stack } from "@mui/material";
import { green, red } from "@mui/material/colors";
import ModalEditSuplier from "../../../../Components/ModalEditSuplier";

export default function Suplier() {
  const [dataCr, setDataCr] = useState(null);
  const [state, action] = useSuplierStore();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  useEffect(() => {
    action.loadData();
  }, []);

  async function hapus(id) {
    let modal = await Swal.fire({
      title: "Yakin data ini Dihapus?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, hapus",
      cancelButtonText: "Batal",
    });
    if (modal.isConfirmed) {
      Swal.showLoading();
      const res = await action.deleteData(id);
      if (res) {
        let dat = await action.loadData();
        if (dat) {
          Swal.fire({
            title: "Sukses hapus data",
            timer: 2000,
            icon: "success",
          });
        }
      }
    }
  }

  const edit = (dat) => {
    setOpenEdit(true);
    setDataCr(dat);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Searchbar action={action} state={state} />
        <div>
          <BtnCus
            variant="contained"
            text="Tambah"
            onClick={() => setOpen(true)}
          />
        </div>
      </div>

      {/* <Paper sx={{ p: "20px", mt: "20px", borderRadius: "20px" }}> */}
      <TableContainer
        sx={{ mt: "20px", borderRadius: "20px" }}
        component={Paper}
      >
        <Table>
          <TableHead sx={{ bgcolor: "#f2edf5" }}>
            <TableRow>
              {["No", "Nama", "No Hp", "Alamat", "Aksi"].map((e, i) => (
                <TableCell key={i} align="left" sx={{ fontWeight: 700 }}>
                  {e}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!state.data ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  Load data . . .
                </TableCell>
              </TableRow>
            ) : state.data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  No Data
                </TableCell>
              </TableRow>
            ) : (
              state.data.map((row, i) => (
                <TableRow key={i}>
                   <TableCell>{state.page * 10 + (i + 1)}</TableCell>
                  <TableCell>{row.nama ?? ""}</TableCell>
                  <TableCell>{row.noHp ?? ""}</TableCell>
                  <TableCell>{row.alamat ?? ""}</TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      <Avatar
                        onClick={() => edit(row)}
                        sx={{ bgcolor: green[500], cursor: "pointer" }}
                      >
                        <EditOutlinedIcon />
                      </Avatar>
                      <Avatar
                        onClick={() => hapus(row.id)}
                        sx={{ bgcolor: red[500], cursor: "pointer" }}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </Avatar>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center" my="20px">
        <PaginationCustom action={action} state={state} />
      </Box>
      <ModalAddSuplier
        open={open}
        close={() => setOpen(false)}
        action={action}
      />
      <ModalEditSuplier
        open={openEdit}
        close={() => setOpenEdit(false)}
        action={action}
        data={dataCr}
      />
    </>
  );
}
