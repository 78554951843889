import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Swal from "sweetalert2";
import TextInput from "./Input/TextInput";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { Box, IconButton, MenuItem, Select } from "@mui/material";
import InputSelect from "./Input/InputSelect";
import { useLabStore } from "../Views/Pages/Farmasi/Lab/store";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useObatStore } from "../Views/Pages/Farmasi/Obat/store";

export default function ModalAddRM(props) {
  const [input, setInput] = useState({
    tanggal: "",
    keluhanUtama: "",
    anamnesa: "",
    diagnosa: "",
    tindakan: "",
    saran: "",
  });
  const [lab, actLab] = useLabStore();
  const [obat, actObat] = useObatStore();

  useEffect(() => {
    if (!lab?.optLabs) actLab.loadOpt();
    if (!obat?.optObats) actObat.loadOpt();
  }, []);

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setInput((inp) => ({ ...inp, [name]: value }));
  };

  useEffect(() => {
    if (props.data?.Rekammedi) {
      setInput({
        tanggal: props.data.Rekammedi.tanggal ?? "",
        keluhanUtama: props.data.Rekammedi.keluhanUtama ?? "",
        anamnesa: props.data.Rekammedi.anamnesa ?? "",
        diagnosa: props.data.Rekammedi.diagnosa ?? "",
        tindakan: props.data.Rekammedi.tindakan ?? "",
        saran: props.data.Rekammedi.saran ?? "",
      });
    } else {
      setInput({
        tanggal: "",
        poliklinik: "",
        idDokter: "",
        caraBayar: "",
      });
    }
  }, [props.open]);

  async function submit(e) {
    e.preventDefault();
    Swal.showLoading();
    let res;
    if (props.data?.Rekammedi) {
      res = await props.action.editRM(props.data.Rekammedi.id, {
        ...input,
        appoinmentId: props.data.id,
        idPasien: props.data.idPasien,
        idDokter: props.data.idDokter,
      });
    } else {
      res = await props.action.addRM({
        ...input,
        appoinmentId: props.data.id,
        idPasien: props.data.idPasien,
        idDokter: props.data.idDokter,
      });
    }
    if (res.ok) {
      await props.action.loadData();
      props.close();
      setInput({
        tanggal: "",
        poliklinik: "",
        idDokter: "",
        caraBayar: "",
      });
      Swal.fire({
        title: "Sukses Simpan data",
        timer: 2000,
        icon: "success",
      });
    } else {
      Swal.fire({
        title: res?.err ?? "Gagal Simpan Data",
        timer: 2000,
        icon: "error",
      });
    }
    setInput({
      tanggal: "",
      poliklinik: "",
      idDokter: "",
      caraBayar: "",
    });
  }

  return (
    <Dialog maxWidth="md" fullWidth scroll="paper" open={props.open}>
      <DialogTitle>Rekam Medis</DialogTitle>
      <DialogContent
        dividers
        // sx={{ overflowY: "scroll", maxHeight: "100%" }}
        tabIndex={-1}
      >
        <Stack spacing={2}>
          <TextInput
            label="Tanggal"
            onChange={onChangeInput}
            name="tanggal"
            value={input.tanggal}
            type="date"
            required={true}
          />
          <TextInput
            label="Keluhan Utama"
            onChange={onChangeInput}
            name="keluhanUtama"
            value={input.keluhanUtama}
            multiline={true}
            rows={2}
            required={true}
          />
          <TextInput
            label="Anamnesa"
            onChange={onChangeInput}
            name="anamnesa"
            value={input.anamnesa}
            multiline={true}
            rows={4}
            required={true}
          />
          <TextInput
            label="Diagnosa"
            onChange={onChangeInput}
            name="diagnosa"
            value={input.diagnosa}
            multiline={true}
            rows={4}
            required={true}
          />
          <TextInput
            label="Tindakan"
            onChange={onChangeInput}
            name="tindakan"
            value={input.tindakan}
            multiline={true}
            rows={4}
            required={true}
          />
          <TextInput
            label="Saran"
            onChange={onChangeInput}
            name="saran"
            value={input.saran}
            multiline={true}
            rows={4}
            required={true}
          />
          <div className="div-input">
            <div className="label-input">Pemeriksaan Penunjang</div>
            {
              <Box display="flex" width="100%">
                <Box width="50%">
                  <Select
                    // value={value}
                    // onChange={onChange}
                    // required={required}
                    fullWidth
                    size="small"
                    // renderValue={
                    //   value !== "" ? undefined : () => <div>Pilih</div>
                    // }
                  >
                    {(lab?.optLabs ?? []).map((e, i) => (
                      <MenuItem key={i} value={e.id}>
                        {e.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box width="50%">
                  <TextInput
                    placeholder="Hasil"
                    style={{ width: 0, marginLeft: "10px" }}
                  />
                </Box>
                <IconButton color="secondary" size="small">
                  <AddCircleIcon fontSize="large" />
                </IconButton>
                <IconButton color="error" size="small">
                  <RemoveCircleIcon fontSize="large" />
                </IconButton>
              </Box>
            }
          </div>
          <div className="div-input">
            <div className="label-input">Resep</div>
            {
              <Box display="flex" width="100%">
                <Box width="40%">
                  <Select
                    // value={value}
                    // onChange={onChange}
                    // required={required}
                    fullWidth
                    size="small"
                    // renderValue={
                    //   value !== "" ? undefined : () => <div>Pilih</div>
                    // }
                  >
                    {(obat?.optObats ?? []).map((e, i) => (
                      <MenuItem key={i} value={e.id}>
                        {e.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box width="20%">
                  <TextInput
                    placeholder="Jumlah"
                    style={{ width: 0, marginLeft: "10px" }}
                  />
                </Box>
                <Box width="40%">
                  <TextInput
                    placeholder="Aturan"
                    style={{ width: 0, marginLeft: "10px" }}
                  />
                </Box>
                <IconButton color="secondary" size="small">
                  <AddCircleIcon fontSize="large" />
                </IconButton>
                <IconButton color="error" size="small">
                  <RemoveCircleIcon fontSize="large" />
                </IconButton>
              </Box>
            }
          </div>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={submit}>
          Simpan
        </Button>
        <Button
          variant="outlined"
          color="primary"
          type="button"
          onClick={props.close}
        >
          Batal
        </Button>
      </DialogActions>
    </Dialog>
  );
}
