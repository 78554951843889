import React, {useState} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import TextInput from "../../../Components/Input/TextInput";
import BtnCus from "../../../Components/Btn/BtnCus";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function TambahPengguna() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [input, setInput] = useState({
    nama: "",
    username: "",
    password: "",
    ulangiPassword: "",
  });

  function onChangeInput(event) {
    let { name, value } = event.target;
    setInput({
      ...input,
      [name]: value,
    });
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  function submit(data) {}

  return (
    <Paper sx={{ borderRadius: "12px" }}>
      <div style={{ padding: "20px" }}>
        <Typography variant="h5">Tambah Pengguna</Typography>
      </div>
      <Divider />
      <Container maxWidth="md">
        <div style={{ padding: "20px" }}>
          <form
            onSubmit={submit}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Stack spacing={2}>
              <TextInput label="Nama Pengguna" />
              <TextInput label="Username" />
              <div className="div-input">
                <div className="label-input">Password</div>
                <TextField
                  size="small"
                  placeholder="Tulis Password"
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  autoComplete="current-password"
                  value={input.password}
                  onChange={onChangeInput}
                  InputProps={{
                    autocomplete: "new-password",
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? (
                            <Visibility className="placeholder" />
                          ) : (
                            <VisibilityOff className="placeholder" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="div-input">
                <div className="label-input">Ulangi Password</div>
                <TextField
                  size="small"
                  placeholder="Tulis Password Kembali"
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  id="password"
                  error={input.password !== input.ulangiPassword}
                  helperText={
                    input.ulangiPassword &&
                    input.password !== input.ulangiPassword
                      ? "Password tidak sesuai"
                      : null
                  }
                  autoComplete="current-password"
                  name="ulangiPassword"
                  value={input.ulangiPassword}
                  onChange={onChangeInput}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? (
                            <Visibility className="placeholder" />
                          ) : (
                            <VisibilityOff className="placeholder" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Stack>
            <div style={{ marginTop: "20px", marginLeft: "185px" }}>
              <BtnCus
                size="small"
                variant="contained"
                type="submit"
                color="primary"
                text="Submit"
                style={{ width: "100px" }}
              />
              <BtnCus
                size="small"
                variant="outlined"
                text="Batal"
                style={{ marginLeft: "12px", width: "100px", color: "#f37435" }}
                onClick={() => navigate(-1)}
              />
            </div>
          </form>
        </div>
      </Container>
    </Paper>
  );
}
