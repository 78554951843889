import { apiClient } from "../../../../../service";

export const viewObatApi = (filter) => {
  const queryString = Object.keys(filter)
    .map((key) => {
      if (filter[key]) {
        return `${key}=${filter[key]}`;
      }
    })
    .join("&");
  return apiClient.get(`/obat/view?${queryString}`);
};
export const addObatApi = (form) => apiClient.post(`/obat/create`, form);
export const deleteObatApi = (id) => apiClient.post(`/obat/delete/${id}`);
export const detailObatApi = (id) => apiClient.get(`/obat/detail/${id}`);
export const editObatApi = (id, form) => apiClient.post(`/obat/update/${id}`, form);

export const addStockApi = (form) => apiClient.post(`/stock/create`, form);
export const viewOptObatApi = () => apiClient.get(`/obat/view/options`);