import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default function InputSelectLabel(props) {
  return (
    <div className="div-input">
      <div className="label-input" style={props.style}>{props.label}</div>
      <Select      
        fullWidth
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          getContentAnchorEl: null,
        }}
        error={props.error}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        displayEmpty
        size="small"
        renderValue={
          props.value === ""
            ? () => <div style={{ color: "#999999" }}>{props.placeholder}</div>
            : undefined
        }
      >
        {props.options &&
          props.options.map((item, i) => {
            return (
              <MenuItem key={i} value={item}>
                {item}
              </MenuItem>
            );
          })}
      </Select>
    </div>
  );
}
