import React, { useState } from "react";
import Swal from "sweetalert2";
import TextInput from "../../../Components/Input/TextInput";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import InputSelectLabel from "../../../Components/Input/Select";
import Dropzone from "react-dropzone";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import BtnCus from "../../../Components/Btn/BtnCus";
import { useNavigate } from "react-router-dom";
import { useArtikelStore } from "./store";

export default function TambahBerita(props) {
  const [, action] = useArtikelStore();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState(null);
  const [input, setInput] = useState({
    judul: "",
    kategori: "",
    tag: "",
  });

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setInput((inp) => ({ ...inp, [name]: value }));
  };

  async function add(e) {
    e.preventDefault();
    Swal.showLoading();
    let temp = new FormData();
    for (const key in input) {
      temp.set(key, input[key]);
    }
    temp.set("body", description);
    temp.append("photo", file);
    const res = await action.addData(temp);
    if (res.ok) {
      await action.loadData();
      setInput({
        judul: "",
        kategori: "",
        tag: "",
      });
      setFile(null);
      Swal.fire({
        title: "Sukses tambah data",
        timer: 2000,
        icon: "success",
      });
      navigate("/loged/berita");
    } else {
      Swal.fire({
        title: res?.err ?? "Gagal Tambah Data",
        timer: 2000,
        icon: "error",
      });
    }
  }

  function changeDesc(value) {
    setDescription(value);
  }

  return (
    <Paper sx={{ borderRadius: "12px", p: "20px" }}>
      <div>
        <Typography variant="h5" mb="12px">
          Tambah Berita/Artikel
        </Typography>
      </div>
      <Divider />
      <Container maxWidth="md">
        <form onSubmit={add}>
          <Stack spacing={2} mt="20px">
            <TextInput
              label="Judul"
              onChange={onChangeInput}
              name="judul"
              value={input.judul}
              required={true}
            />
            <InputSelectLabel
              label="Pilih Kategori"
              onChange={onChangeInput}
              name="kategori"
              value={input.kategori}
              options={["Berita", "Artikel"]}
              required={true}
            />
            <div className="div-input">
              <div className="label-input">Upload Foto</div>
              <div className="dropzone-div">
                <Dropzone onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="dropzone-div-section">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {!file ? (
                          <div
                            style={{
                              flexDirection: "column",
                              height: "fit-content",
                            }}
                          >
                            <UploadFileOutlinedIcon
                              sx={{ fontSize: "100px", color: "#999999" }}
                            />
                            <div style={{ marginBottom: "10px" }}>
                              Upload foto maksimal ukuran 1 MB
                            </div>
                            <BtnCus
                              text="Upload Foto"
                              variant="outlined"
                              style={{ height: "30px", color: "orange" }}
                              size="small"
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              padding: "20px",
                            }}
                          >
                            <img src={URL.createObjectURL(file)} width="100%" />
                            <p>{file.name}</p>
                            <BtnCus
                              color="secondary"
                              onClick={() => setFile(null)}
                              variant="outlined"
                              text="Ganti"
                              soze="small"
                              style={{ color: "green" }}
                            />
                          </div>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
            <div className="div-input">
              <div className="label-input">Isi Berita/Artikel</div>
              <SunEditor
                value={description}
                setContents={description}
                onChange={changeDesc}
                setOptions={{ minHeight: "250px" }}
              />
            </div>
          </Stack>
          <div style={{ marginTop: "20px", marginLeft: "185px" }}>
            <BtnCus
              size="small"
              variant="contained"
              type="submit"
              color="primary"
              text="Submit"
              style={{ width: "100px" }}
            />
            <BtnCus
              size="small"
              variant="outlined"
              text="Batal"
              style={{ marginLeft: "12px", width: "100px", color: "#f37435" }}
              onClick={() => navigate(-1)}
            />
          </div>
        </form>
      </Container>
    </Paper>
  );
}
