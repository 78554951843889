import { Divider, IconButton, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextInput from "../../../Components/Input/TextInput";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import LihatTagihan from "./LihatTagihan";
import BtnCus from "../../../Components/Btn/BtnCus";
import { useNavigate } from "react-router-dom";

const headTable = [
  "No",
  "Keterangan",
  "Biaya Per Satuan",
  "Kuantitas",
  "Sub Total",
];

export default function BuatTagihan() {
  const navigate = useNavigate()
  const pasien = [
    { label: "Tanggal", value: "0909" },
    { label: "No. RM", value: "0909" },
    { label: "Nama", value: "0909" },
    { label: "Alamat", value: "Lorem ipsum dolor" },
  ];

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Paper
        sx={{ width: "1000px", p: "20px", borderRadius: "20px", mb: "20px" }}
      >
        <div style={{ paddingBottom: "20px" }}>
          <Typography variant="h5">Tambah Biaya</Typography>
        </div>
        <Divider sx={{ mb: "20px" }} />
        <div className="div-input">
          {
            <Box display="flex" width="100%">
              <Box width="50%">
                <TextInput
                  placeholder="Keterangan"
                  style={{ width: 0, marginLeft: "10px" }}
                />
              </Box>
              <Box width="50%">
                <TextInput
                  placeholder="Biaya Per Satuan"
                  style={{ width: 0, marginLeft: "10px" }}
                />
              </Box>
              <Box width="50%">
                <TextInput
                  placeholder="Kuantitas"
                  style={{ width: 0, marginLeft: "10px" }}
                />
              </Box>
              <Box width="50%">
                <TextInput
                  disabled={true}
                  placeholder="Jumlah"
                  style={{ width: 0, marginLeft: "10px" }}
                />
              </Box>
              <IconButton color="secondary" size="small">
                <AddCircleIcon fontSize="large" />
              </IconButton>
              <IconButton color="error" size="small">
                <RemoveCircleIcon fontSize="large" />
              </IconButton>
            </Box>
          }
        </div>
        <div style={{ marginTop: "20px"}}>
          <BtnCus
            size="small"
            variant="contained"
            type="submit"
            color="primary"
            text="Submit"
            style={{ width: "100px" }}
          />
          <BtnCus
            size="small"
            variant="outlined"
            text="Batal"
            style={{ marginLeft: "12px", width: "100px", color: "#f37435" }}
            onClick={() => navigate(-1)}
          />
        </div>
      </Paper>
      <LihatTagihan />
    </div>
  );
}
